/* eslint-disable */
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import MainTitle from '../../../components/MainTitle'
import useAuth from '../../../hooks/useAuth'
import { useState, useEffect, Link, Fragment } from 'react'
import Pagination from '../../../components/Pagination';
import { useParams, useLocation, useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faEye, faSortUp, faSort, faSortDown, faCircleCheck, faTrash, faCircleXmark, faUserPlus, faLink, faChevronDown, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from '../../../components/Modal'
import { Menu, Transition } from '@headlessui/react'
import ViewEnquiry from '../../../components/ViewEnquirySlideOut';

const OrganisationEnquiries = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [organisationData, setOrganisationData] = useState({
    name: '',
    reference: 'HUB-1',
    kis_link: '',
    wba_link: ''
  })

  const { setShowLoader, can, createInfo, auth, admin } = useAuth()
  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentRecords, setCurrentRecords] = useState([])
  const navigate = useNavigate();

  const [search, setSearch] = useState('')
  const [statusFilter, setStatusFilter] = useState('');

  // No of Records to be displayed on each page
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [closeEnquiry, setCloseEnquiry] = useState(false)
  const [deleteEnquiry, setDeleteEnquiry] = useState(false)
  const [organisations, setOrganisations] = useState({})
  const [linkOrganisation, setLinkOrganisation] = useState({})
  const [linkOrganisationModal, setLinkOrganisationModal] = useState(false)
  //
  const [open, setOpen] = useState(false)

  const [enquiries, setEnquiries] = useState([])
  const [reasons, setReasons] = useState([])
  const [statuses, setStatuses] = useState([])
  const [cancelReasons, setCancelReasons] = useState({})
  const [enquiry, setEnquiry] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const [enquiryStatuses, setEnquiryStatuses] = useState({})

  const [assignUser, setAssignUser] = useState(false)
  const [userAssigned, setUserAssigned] = useState([])
  const [users, setUsers] = useState([]);
  const [userAssignedId, setUserAssignedId] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');

  const getOrganisationEnquiries = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/enquiries/by/organisation/` + id)
      let filteredEnquiries = response.data.result;
      if (statusFilter) {
        filteredEnquiries = filteredEnquiries.filter(enquiry => enquiry.statuses.title.toLowerCase() === statusFilter);
      }
      if (filteredEnquiries) {
        await setEnquiries(filteredEnquiries)
        await setOriginalRecords(filteredEnquiries)
        await setCurrentRecords(filteredEnquiries)
        await setFilteredRecords(filteredEnquiries)
        setShowLoader(false)
      }
    } catch (error) {
      console.error(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const getOrganisationById = async (id) => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/organisations/` + id)
      setOrganisationData(response.data.result)
    } catch (error) {
      console.error(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const viewEnquiryClicked = (enquiry) => {
    setOpen(true)
    setEnquiry(enquiry)
  }

  const fetchUsers = async () => {
    try {
      setShowLoader(true);
      const usersResponse = await axios.get('/users/all');
      if (usersResponse.data.result) {
        setUsers(usersResponse.data.result);
      }

    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
    setShowLoader(false)
  };


  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value.toLowerCase());
    setDropdownValue(e.target.value);
  }

  const getStatuses = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/enquiries/statuses`)
      if (response.data) {
        await setEnquiryStatuses(response.data.result)
        setShowLoader(false)
      }
    } catch (error) {
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const resetFilters = () => {
    setStatusFilter('');
    setSearch('');
    setDropdownValue('');
  };

  const isFilterApplied = () => {
    return statusFilter !== '' || search !== '';
  };

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = enquiries.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(enquiries)
      setCurrentRecords(enquiries.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(enquiries.length / recordsPerPage))
    }
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });

      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const resetModal = () => {
    setShowModal(false)
    setAssignUser(false)
    setModalTitle('')
  }

  const AssignUserElement = () => {
    const completeEnquiry = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const userAssigned = users.find(user => user.id.toString() === userAssignedId);
        if (!userAssigned) throw new Error('User not found');
        const response = await axios.post(`/enquiries/update-user/${enquiry.id}`, {
          assigned_to_id: userAssigned.id,
          enquiry_id: enquiry.id,
        });
        createInfo('success', `Enquiry assigned successfully`);
        getOrganisationEnquiries(id)
      } catch (error) {
        console.error(error);
        createInfo('error', error.response?.data?.message || `Failed to assign enquiry`);
      } finally {
        resetModal();
        setShowLoader(false);
      }
    };

    return (
      <form onSubmit={completeEnquiry}>
        <div className="mb-3">
          Please choose a user to assign to <span className='font-medium'>{enquiry.name}</span> from the list below:
        </div>
        <div className="mb-3">
          <label htmlFor="userSelect" className="block font-medium text-gray-600 text-sm">
            Users <span className="text-red-500">*</span>
          </label>
          <select
            defaultValue={userAssignedId}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md capitalize"
            required
            id="userSelect"
            onChange={(e) => setUserAssignedId(e.target.value)}
          >
            <option disabled value="">
              Select a user
            </option>
            {users?.map((user) => (
              <option key={user.id} value={user.id}>
                {user?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex mt-2 w-full">
          <button type="submit" className="btn mt-4 mr-4 w-1/2">Assign</button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>Cancel</button>
        </div>
      </form>
    );
  };


  const assignUserClicked = (enquiry) => {
    setAssignUser(true);
    setEnquiry(enquiry);
    setModalTitle(`Assign User to Enquiry`);
    setShowModal(true);
  };

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {

      const segments = key.split('.');
      let aKey = a;
      let bKey = b;

      segments.forEach(element => {
        if (!aKey[element]) {
          aKey = '';
          return;
        }

        if (!bKey[element]) {
          bKey = '';
          return;
        }

        aKey = aKey[element];
        bKey = bKey[element];
      });

      if (aKey < bKey) return direction === 'ascending' ? -1 : 1;
      if (aKey > bKey) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);

      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const ModalBody = () => {
    if (assignUser) {
      return <AssignUserElement />
    }

  }


  const createEnquiry = () => {
    let data = {
      name: organisationData.name,
      email: organisationData.email,
      dateOfBirth: organisationData.organisation_details.date_of_birth,
      phoneNumber: organisationData.organisation_details.phone_number
    }
    return navigate(`/admin/new-enquiry`, { state: { enquiry_data: data } })
  }


  useEffect(() => {
    getStatuses();
    fetchUsers();
    getOrganisationEnquiries(id)
    getOrganisationById(id)
  }, [statusFilter, id])

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <ViewEnquiry open={open} setOpen={setOpen} enquiry={enquiry} />
      <section className="mt-16 overflow-x-scroll">
        <div className='relative'>
          <div className="flex items-center w-full justify-between"></div>
          <div className="pb-4 flex">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  onClick={handleSearch}
                />
              </div>
              <input
                type="text"
                id="table-search"
                onChange={handleSearch}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-80 pl-10 p-2.5  "
                placeholder="Search Enquiries"
              />
            </div>
            {can('view organisation status') &&
              <div className="ml-auto relative">
                <select
                  onChange={handleStatusChange}
                  value={dropdownValue}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-md mr-2"
                >
                  <option value="">Filter by Status</option>
                  {enquiryStatuses.length > 0 && enquiryStatuses?.map((status, index) => {
                    return <option key={index} value={status.title}>
                      {status.title}
                    </option>
                  })}
                </select>
                {isFilterApplied() && (
                  <button
                    onClick={resetFilters}
                    className="bg-red-500 text-white rounded-lg px-4 py-2 mr-2"
                  >
                    Reset Filters
                  </button>
                )}
              </div>
            }
          </div>

          <table className="table-main mt-4 mb-10">
            <thead>
              <tr>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('name')}>Name {renderSortArrow('name')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('organisation')}>Organisation {renderSortArrow('organisation')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('reference')}>Reference {renderSortArrow('reference')}</th>
                {can('view organisation status') && <th className='cursor-pointer' scope="col" onClick={() => handleSort('statuses.title')}>Status {renderSortArrow('statuses.title')} </th>}
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('user.name')}>Assigned To {renderSortArrow('user.name')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('created_at')}>Created At {renderSortArrow('created_at')}</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 ? (
                currentRecords?.map((enquiry) => {
                  return (
                    <tr key={enquiry.id}>
                      <td>{enquiry.name}</td>
                      <td>
                        {enquiry.organisation_id !== null ? (
                          <a href={`/organisations/${enquiry.organisation_id}`} className='hover:text-violet-500 hover:underline cursor-pointer'>{enquiry.organisation.name ?? enquiry.organisation_id}</a>
                        ) : (
                          <div>No linked organisations</div>
                        )}
                      </td>

                      <td>{enquiry.reference}</td>
                      <td>
                        {enquiry.enquiryReasons !== null ? (
                          <div className={`bg-rose-200 text-rose-800 rounded-full py-1 px-2 capitalize`}>{enquiry.enquiryReasons}</div>
                        ) : (
                          <div className={`bg-${enquiry.statuses.colour}-200 text-${enquiry.statuses.colour}-800 rounded-full py-1 px-2 capitalize`}>{enquiry.statuses.title}</div>
                        )}
                      </td>
                      <td>{enquiry.user?.name}</td>
                      <td>{moment(enquiry.created_at).format('DD/MM/YYYY')}</td>
                      <td>
                        <Menu as="div" className=" inline-block text-left">
                          <div>
                            <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2 hover:bg-gray-300 px-2 py-1">
                              <span className="font-semibold text-sm mr-2">
                                Actions
                              </span>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="p-2 space-y-2">
                                {(enquiry.statuses?.title?.toLowerCase() !== 'escalated to nurses' || (enquiry.statuses?.title?.toLowerCase() === 'escalated to nurses' && can('access escalated to nurses enquiries'))) &&
                                  <Menu.Item>
                                    <div onClick={() => viewEnquiryClicked(enquiry)} title="View Enquiry" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer">
                                      <FontAwesomeIcon icon={faEye} className='text-blue-800' /> <div className='pl-2'>View</div>
                                    </div>
                                  </Menu.Item>
                                }
                                {(enquiry.statuses?.title?.toLowerCase() !== 'escalated to nurses' || (enquiry.statuses?.title?.toLowerCase() === 'escalated to nurses' && can('update escalated to nurses enquiries'))) &&
                                  <>
                                    <Menu.Item>
                                      <div onClick={() => assignUserClicked(enquiry)} title="Assign User" className="flex justify-start items-center rounded-md px-2 py-1 hover:bg-pink-200 cursor-pointer">
                                        <FontAwesomeIcon icon={faUserPlus} className='text-pink-800' /> <div className='pl-2'>Assign User</div>
                                      </div>
                                    </Menu.Item>
                                    <>
                                      {enquiry.enquiryReasons === '' && (
                                        <Menu.Item >
                                          <div onClick={() => closeEnquiryClicked(enquiry)} title="Close Enquiry" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-amber-200 cursor-pointer">
                                            <FontAwesomeIcon icon={faCircleXmark} className='text-amber-800' /> <div className='pl-2'>Close Enquiry</div>
                                          </div>
                                        </Menu.Item>
                                      )}
                                    </>
                                    <Menu.Item >
                                      <>
                                        {enquiry.organisationName === '' && (
                                          <div onClick={() => linkOrganisationClicked(enquiry)} title="Link organisation" className="flex justify-start items-centerrounded-md  px-2 py-1 hover:bg-violet-200 cursor-pointer">
                                            <FontAwesomeIcon icon={faLink} className='text-violet-800' /> <div className='pl-2'>Link organisation</div>
                                          </div>
                                        )}
                                      </>
                                    </Menu.Item>
                                  </>
                                }
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <p className="no-records">No enquiries found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRecords.length}
          />
        </div>
      </section>
    </section>
  )
}

export default OrganisationEnquiries

