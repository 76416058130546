import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'

const SlideOut = ({ open, setOpen }) => {
  const { setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const [error, setError] = useState('')
  const [newTask, setNewTask] = useState({})

  const createTask = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
      const response = await axios.post(`/tasks/create`, {
        title: newTask.title,
        due_date: newTask.due_date,
        important: newTask.important,
      });

      if (response.data.error === false) {
        createInfo('success', `Task Added`);
        setNewTask({})
        setOpen(false)
      }
    } catch (error) {
      console.error(error.message);
      createInfo('error', `Failed to create task`);
    }
    setShowLoader(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl relative">
                    <div className="bg-nav px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <Dialog.Title className="text-lg font-semibold leading-6 text-white">
                            Create New Task
                          </Dialog.Title>
                          <div className="text-xs text-white">
                            Please complete the required information below.
                          </div>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-nav text-white hover:text-nav-dark focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faCircleXmark} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={createTask}>
                      <div className="relative h-full mt-6 flex-1 px-4 sm:px-6">
                        <div className="px-4 py-2">
                          <div className="flex justify-between space-x-6">
                            <div className="mt-4 w-full">
                              <label htmlFor="">
                                Title <span className="text-red-500">*</span>
                              </label>
                              <input
                                required
                                value={newTask.title}
                                onChange={(e) => newTask.title = e.target.value}
                                type="text"
                                name="title"
                                id="title"
                                className="border border-nav-dark rounded-md py-2 px-4 w-full"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col mt-4">
                            <label htmlFor="">
                              Completion Date
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              required
                              type="date"
                              value={newTask.due_date}
                              onChange={(e) => newTask.due_date = e.target.value}
                              name="due_date"
                              id="due_date"
                              className="border border-nav-dark rounded-md py-2 px-4 cursor-pointer"
                              style={{ width: '100%' }}
                            />
                            {error && <p className="text-red-500 mt-2">{error}</p>}
                          </div>
                          <div className="flex mt-4">
                            <input
                              value={newTask.important}
                              onChange={(e) => newTask.important = e.target.value}
                              type="checkbox"
                              className="bg-nav-dark focus:accent-nav-dark h-4 w-4 mr-2"
                            />
                            <label htmlFor="checkbox">
                              Important
                            </label>
                          </div>
                          <div className="text-xs mt-2">
                            <span className="text-red-500">*</span> Denotes required
                            fields.
                          </div>
                        </div>
                      </div>

                      <div className="w-full px-6 mb-10 absolute bottom-0">
                        <button
                          type="submit"
                          className='border flex items-center rounded h-[45px] w-full'
                        >
                          <div className="text-nav h-full flex justify-center items-center px-10 font-medium w-full">
                            Create
                          </div>
                          <div className="bg-nav text-white h-full flex w-[45px] justify-center items-center">
                            <FontAwesomeIcon icon={faPlus} />
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SlideOut
