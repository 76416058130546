/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams, } from 'react-router-dom'
import Modal from '../../../components/Modal'
import { ImageConfig } from '../../../components/ImageConfig'
import {
  faCalendarPlus,
  faCircleXmark,
  faFile,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
// import './drop-file-input.css'

const ReferralDocuments = (viewingRequest) => {
  //Auth
  const { id } = useParams()
  const { setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()

  const getSharedDocuments = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(`/clients/${id}/documents`)

      if (response.data.result) {
        setShowFiles(response.data.result)
      }
    } catch (error) { }
    setShowLoader(false)
  }

  const getCommAttachments = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(`/clients/${id}/comm-attachments`)
      if (response.data.result) {
        setCommAttachments(response.data.result)
      }
    } catch (error) { }
    setShowLoader(false)
  }

  useEffect(() => {
    getKISDocs(viewingRequest.viewingRequest.id)
  }, [])

  //drag and drop
  const wrapperRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [showFiles, setShowFiles] = useState([])
  const [commAttachments, setCommAttachments] = useState([])
  const [docsList, setDocsList] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false)

  const onDragEnter = () => wrapperRef.current.classList.add('dragover')
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover')
  const onDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const onDrop = (e) => {
    e.preventDefault()
    wrapperRef.current.classList.remove('dragover')
    onFileDrop(e.nativeEvent.dataTransfer.files)
  }

  const getKISDocs = async (claimId) => {
    try {
      setShowLoader(true)
      const response = await axios.post(
        `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/documents/referral/${claimId}?api_token=${process.env.REACT_APP_API_KEY}`
      );

      if (response.data && response.data.data) {
        setDocsList(response.data.data);
      }

      setShowLoader(false);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setShowLoader(false);
    }
  }

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const allowedFileTypes = [
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    '.csv',
    'application/pdf',
    '.pdf',
    'image/jpeg',
    '.jpg',
    'image/png',
    '.png',
    'image/tiff',
    '.tiff',
  ];

  const isValidFileType = (file) => {
    const extension = `.${file.name.split('.').pop()}`;
    const mimeType = file.type;
    return (
      allowedFileTypes.includes(extension) ||
      allowedFileTypes.includes(mimeType)
    );
  };

  const onFileUpload = async (e) => {
    e.preventDefault();
    const newFile = e.target.files[0];

    if (newFile && isValidFileType(newFile)) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
    } else {
      console.error('Invalid file type. Please select a valid file.');
    }
  };

  const onFileDrop = async (files) => {
    const newFile = files[0];

    if (newFile && isValidFileType(newFile)) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
    } else {
      createInfo('error', 'Invalid file type');
      return
    }
  };

  // const validateSelectedFile = async () => {
  //   const file_size = await axios.get(`/clients/max-file-size`)

  //   const MAX_FILE_SIZE = file_size.value // 10MB

  //   if (!selectedFile) {
  //     setErrorMsg('Please choose a file')
  //     setIsSuccess(false)
  //     return
  //   }

  //   const fileSizeKiloBytes = selectedFile.size / 1024

  //   if (fileSizeKiloBytes > MAX_FILE_SIZE) {
  //     setErrorMsg('File size is greater than maximum limit')
  //     setIsSuccess(false)
  //     return
  //   }

  //   setErrorMsg('')
  //   setIsSuccess(true)
  // }

  const uploadDocuments = async () => {
    setShowLoader(true);
    const fd = new FormData();

    for (let i = 0; i < fileList.length; i++) {
      fd.append('file[]', fileList[i], fileList[i].name);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/upload/document?area=policy/claims/${viewingRequest.viewingRequest.id}&type=wbsclaim&cid=${viewingRequest.viewingRequest.id}`,
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.result) {
        setShowFiles([...showFiles, ...response.data.result]);
      }

      setFileList([]);
      getKISDocs(viewingRequest.viewingRequest.id);
      createInfo(
        'success',
        'Uploaded ' + response.data.result.length + ' document(s)'
      );
    } catch (error) {
      console.error('File upload error:', error);
    } finally {
      setShowLoader(false);
    }
  };


  const fileRemove = (file) => {
    const updatedList = [...fileList]
    updatedList.splice(fileList.indexOf(file), 1)
    setFileList(updatedList)
  }

  const deleteDocument = async (file) => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/clients/${id}/delete-document/${file.id}`
      )

      if (!response.data.error) {
        const updatedList = [...showFiles]
        updatedList.splice(showFiles.indexOf(file), 1)
        setShowFiles(updatedList)

        createInfo('success', 'Document removed')
      } else {
        createInfo('error', 'Could not remove document')
      }
    } catch (error) {
      createInfo('error', 'Could not remove document')
    }
    setShowLoader(false)
  }


  const ModalBody = () => {
    if (assignRisk) {
      return <CreateRiskAssignElement />
    }
  }

  const resetModal = () => {
    setShowModal(false)
  }

  return (
    <section className='flex items-start justify-between space-x-4'>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <section className="w-1/4">
        <div className="flex items-center justify-between mb-4 text-sm">
          <h2 className="font-bold">
            Upload Documents
          </h2>
          <button
            onClick={uploadDocuments}
            className="rounded-lg border text-xs  border-teal-500 bg-teal-500 text-white px-3 py-1 flex items-center hover:bg-transparent hover:text-teal-500"
          >
            <FontAwesomeIcon icon={faUpload} />
            <span className="pl-2"> Upload</span>
          </button>
        </div>
        <label
          htmlFor="file-upload"
          className="col-span-full"
          ref={wrapperRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium leading-6 text-gray-900"
          ></label>
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div className="text-center">
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="mx-auto h-12 w-12 text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                <div className="relative cursor-pointer rounded-md bg-white font-semibold text-dark-purple focus-within:outline-none focus-within:ring-2 focus-within:ring-nav focus-within:ring-offset-2 hover:text-dark-purple">
                  <span>Drag and Drop or click to upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,text/csv,.pdf,application/pdf,image/jpeg,image/png,image/tiff"
                    multiple
                    className="sr-only"
                    onChange={onFileUpload}
                  />
                </div>
                {/* <p className="pl-1">or drag and drop</p> */}
              </div>
              <p className="text-xs leading-5 text-gray-600">
                PNG, JPG, CSV, XLS or PDF up to 10MB
              </p>
              <p className="error-message">{errorMsg}</p>
            </div>
          </div>
        </label>
        <div className="text-xs mt-8">
          {fileList?.map((item, index) => (
            <div
              key={index}
              className="drop-file-preview__item flex items-center justify-between "
            >
              <div className="flex items-center">
                <img
                  src={
                    ImageConfig[item.type.split('/')[1]] ||
                    ImageConfig['default']
                  }
                  alt=""
                  className="w-10"
                />

                <div className="drop-file-preview__item__info">
                  <p className="break-words">{item.name}</p>
                  <p>{item.size}</p>
                </div>
              </div>

              <span
                className=" hover:text-red-700 drop-file-preview__item__del"
                onClick={() => fileRemove(item)}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className=" text-gray-700 hover:text-red-700 w-4 h-4"
                />
              </span>
            </div>
          ))}
        </div>
      </section>
      <section className="w-3/4">
        <h2 className="font-bold mb-4 px-4">
          Documents
        </h2>
        <div className=' p-4  border rounded-lg'>
          <div className='grid grid-cols-4 gap-2'>
            {docsList.map((item, index) => (
              !item.isEvidence && (
                <div className="text-xs space-y-1" key={item.id}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" className=''>
                    <div className="flex items-center bg-light-blue rounded-lg p-3 mb-2">
                      <FontAwesomeIcon icon={faFile} className='h-8 w-8 text-nav-dark ' />
                      <span className="pl-2">{item.name}</span>
                    </div>
                  </a>
                  <p><FontAwesomeIcon icon={faCalendarPlus} className='h-4 w-4 text-hub-green-light hover:text-teal-500 pr-2' /> {new Date(item.createdAt).toLocaleDateString()}</p>
                  {/* {canDelete && ( */}
                  <div onClick={() => deleteDocument(item.id)} className='flex items-center'>
                    <FontAwesomeIcon icon={faCircleXmark} className='h-4 w-4 text-red-700 hover:text-red-500 pr-2' /><span>Remove</span>
                  </div>
                  {/* )} */}
                </div>
              )
            ))}
          </div>
        </div>
        <div>
          {viewingRequest?.service_request?.length > 0 && (
            <>

            </>
          )}
        </div>
      </section>
    </section>
  )
}

export default ReferralDocuments
