
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth'

const OHResultForm = ({ requestData, resetModal, taskId }) => {
  const axios = useAxiosPrivate()

  const { createInfo, setShowLoader } = useAuth();
  const [formData, setFormData] = useState({
    employer: requestData.employer,
    employerEmail: requestData.employerEmail,
    employeeName: requestData.employeeName,
    employeeDOB: requestData.employeeDOB,
    employeeRole: requestData.employeeRole,
    result: null,
    chargeable: false,
    additional_info: '',
    wbs_case_id: requestData.wbs_case_id,
    claimNumber: requestData.claim_reference,
    department: "SWB",
  });

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
      try {

        const response = await axios.post(`${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/wbs/send-wbs-form/oh-result?api_token=${process.env.REACT_APP_API_KEY}`, formData);

        if (response.status === 200) {
          const taskResponse = await axios.post(`/tasks/completed/${taskId}`, {
            completed: 1,
          });

          if (taskResponse.data) {
            createInfo('success', 'Result Sent and Task Completed');
            resetModal();
          }
        }
      } catch (error) {
        console.error('Failed to complete task', error);
      }
    } catch (err) {
      console.error('Error processing request:', err);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="font-bold text-lg mb-2">Staff Details</h2>
      <div className="grid grid-cols-2 gap-4">
        {[
          { label: 'Employer/School Name', field: 'employer', type: 'text' },
          { label: 'Employer/School Email', field: 'employerEmail', type: 'text' },
          { label: 'Prospective Employee Name', field: 'employeeName', type: 'text' },
          { label: 'Prospective Employee Date of Birth', field: 'employeeDOB', type: 'date' },
          { label: 'Role', field: 'employeeRole', type: 'text' },
        ].map((input) => (
          <div className="flex-col items-center mb-2" key={input.field}>
            <label className="text-sm font-semibold mb-1 block">{input.label}:</label>
            <input
              type={input.type}
              value={formData[input.field]}
              onChange={(e) => handleInputChange(input.field, e.target.value)}
              className="text-sm border border-gray-300 rounded-md p-1 w-full"
            />
          </div>
        ))}
      </div>
      <h2 className="font-bold text-lg mb-2">
        Result <span className="text-red-500">*</span>
      </h2>
      <div className="items-center mt-2 pl-2">
        <div className={"ml-2"}>
          <div className={"flex mb-2"}>
            <input
              value="a"
              type="radio"
              name="oh_result"
              className={"mr-2"}
              checked={formData.result === "a"}
              onChange={(e) => {
                handleInputChange('result', e.target.value);
                setFormData((prevData) => ({ ...prevData, chargeable: false }));
              }}
              required
            />
            <label htmlFor="oh_result">No barrier to employment in role disclosed</label>
          </div>
          <div className={"flex mb-2"}>
            <input
              value="b"
              type="radio"
              name="oh_result"
              className={"mr-2"}
              checked={formData.result === "b"}
              onChange={(e) => handleInputChange('result', e.target.value)}
              required
            />
            <label htmlFor="oh_result">Disclosures, no barrier</label>
          </div>
          <div className={"flex"}>
            <input
              value="c"
              type="radio"
              name="oh_result"
              className={"mr-2"}
              checked={formData.result === "c"}
              onChange={(e) => handleInputChange('result', e.target.value)}
              required
            />
            <label htmlFor="oh_result">Disclosures made, recommendations below.</label>
          </div>
        </div>
      </div>
      {(formData.result === "b" || formData.result === "c") && (
        <div className="items-center mt-2 pl-2">
          <label className="text-sm font-semibold mb-1 block">
            Additional Information
          </label>
          <textarea
            value={formData.disclosures}
            onChange={(e) => handleInputChange('additional_info', e.target.value)}
            rows={3}
            className="text-sm border border-gray-300 rounded-md p-1 w-full"
          />
        </div>
      )}
      <div className="flex items-center mt-2 pl-2">
        <label className="text-sm mr-2">Chargeable:</label>
        <input
          type="checkbox"
          checked={formData.chargeable}
          disabled={formData.result === "a"}
          onChange={() => handleInputChange('chargeable', !formData.chargeable)}
        />
      </div>
      <div className="text-xs mt-2 flex justify-end">
        <span className="text-red-500">*</span> denotes required fields.
      </div>
      <div className="flex mt-2 w-full">
        <button type="submit" className="btn mt-4 mr-4 w-1/2">
          Submit Result
        </button>
        <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default OHResultForm;
