/* eslint-disable */

import moment from 'moment'
import { useRef, useEffect } from 'react'
import Calendar from '../modules/toastui-react-calendar'
import '../modules/toastui-calendar.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarPlus,
  faChevronLeft,
  faChevronRight,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Modal from './Modal'
import { useNavigate } from 'react-router-dom'

function TuiCalendar({ events, loadEvents, calendarType, userId = null }) {
  const WEEK_DAY_START = 1
  const DAY_START_HOUR = 7
  const DAY_END_HOUR = 23

  const [currentRange, setCurrentRange] = useState({
    from: moment().startOf('isoWeek'),
    to: moment().endOf('isoWeek'),
  })
  const navigate = useNavigate()
  const [availablilityTypes, setAvailablilityTypes] = useState([])

  const [addAvailabilityFlag, setAddAvailabilityFlag] = useState(false)
  const [editAvailabilityFlag, setEditAvailabilityFlag] = useState(false)
  const [editBookingFlag, setEditBookingFlag] = useState(false)
  const [viewBookingFlag, setViewBookingFlag] = useState(false)
  const [deleteAvailability, setDeleteAvailability] = useState({})
  const [availabilityData, setAvailabilityData] = useState([])
  const [availabilityDate, setAvailabilityDate] = useState('')
  const [viewBookingDate, setViewBookingDate] = useState('')
  const [viewBookingSlot, setViewBookingSlot] = useState('')
  const [viewBookingClient, setViewBookingClient] = useState('')
  const [startTimeValid, setStartTimeValid] = useState(true)
  const [endTimeValid, setEndTimeValid] = useState(true)
  const [cancelSession, setCancelSession] = useState({})
  const [cancelReasons, setCancelReasons] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [bookingLoaded, setBookingLoaded] = useState(false)
  const [availabilityLoaded, setAvailabilityLoaded] = useState(false)

  const { setShowLoader, createInfo, admin } = useAuth()
  const axios = useAxiosPrivate()

  const [loaded, setLoaded] = useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const bookingCalendarRef = useRef()
  const avalabilityCalendarRef = useRef()

  useEffect(() => {
    bookingCalendarRef.current.calendarInstance.clear()
    avalabilityCalendarRef.current.calendarInstance.clear()
    const data = []
    events.forEach((event) => {
      if (calendarType === 'bookings' && userId) {
        let colour;
        if (event.cancelled === 1) {
          colour = '#fca5a5';
        } else if (event.type_id === 1) {
          colour = '#94a3b8';
        } else {
          colour = event.service.calendar_bg_color;
        }

        data.push({
          id: 1,
          body: event.type.title,
          start: event.start_time,
          end: event.end_time,
          attendees: [event.type],
          color: '#fff',
          backgroundColor: colour,
          borderColor: event.service.calendar_border_color,
          raw: event,
        })
      }

      if (calendarType === 'bookings' && !userId) {
        data.push({
          id: 1,
          body: event.service.title,
          start: event.start_time,
          end: event.end_time,
          attendees: [event.type],
          color: '#fff',
          backgroundColor: event.service.calendar_bg_color,
          borderColor: event.service.calendar_border_color,
          raw: event,
        })
      }
      if (calendarType === 'availability') {
        data.push({
          id: event.id,
          start: event.start_time,
          end: event.end_time,
          attendees: [event.type],
          color: '#fff',
          backgroundColor: '#2dd4bf',
          borderColor: '#2dd4bf',
          raw: event,
        })
      }
      // }
    })

    if (calendarType === 'bookings') {
      bookingCalendarRef.current.calendarInstance.createEvents(data)
    }
    if (calendarType === 'availability') {
      avalabilityCalendarRef.current.calendarInstance.createEvents(data)
    }
  }, [events])

  const getAvailabilityTypes = async () => {
    try {
      const response = await axios.get(`/availability/types/all`)
      setAvailablilityTypes(response.data.result)
    } catch (error) { }
  }

  useEffect(() => {
    if (userId) {
      getAvailabilityTypes()
    }
  }, [])

  useEffect(() => {
    if (calendarType === 'bookings' && !bookingLoaded) {
      setBookingLoaded(true)
      bookingCalendarRef.current.calendarInstance.on(
        'selectDateTime',
        selectTime
      )
      if (userId) {
        bookingCalendarRef.current.calendarInstance.on('clickEvent', clickEvent)
      } else {
        bookingCalendarRef.current.calendarInstance.on(
          'clickEvent',
          nonUserClickEvent
        )
      }
    }
    if (calendarType === 'availability' && !availabilityLoaded) {
      setAvailabilityLoaded(true)
      avalabilityCalendarRef.current.calendarInstance.on(
        'selectDateTime',
        selectTime
      )
      if (userId) {
        avalabilityCalendarRef.current.calendarInstance.on(
          'clickEvent',
          clickEvent
        )
      }
    }

    loadEvents(
      moment().startOf('isoWeek').format('YYYY-MM-DD'),
      moment().endOf('isoWeek').format('YYYY-MM-DD')
    )
    setLoaded(true)
    setCurrentRange({
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek'),
    })
  }, [calendarType])

  const getTimeTemplate = (schedule, isAllDay) => {
    let html = []
    let start = moment(new Date(schedule.start))
    let end = moment(new Date(schedule.end))
    if (!isAllDay) {
      html.push(
        '<span style="margin-top: 5px; margin-left: 2px; display: flex"><svg style="width: 15px; fill: white; margin-right: 4px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg><strong style="font-size: 12px">' +
        start.format('HH:mm') +
        ' - ' +
        end.format('HH:mm') +
        '</strong></span>'
      )
    }

    if (schedule.isPending) {
      html.push('<i title="Pending" class="far fa-hourglass-half"></i>')
      html.push(' ' + schedule.title + ' ')
      html.push('<span style="font-weight: lighter;">Pending</span>')
    } else if (schedule.raw && schedule.raw.isCancelled) {
      html.push('<i title="Cancelled" class="far fa-do-not-enter"></i>')
      html.push(' ' + schedule.title + ' ')
      html.push('<span style="font-weight: lighter;">Cancelled</span>')
    } else {
      html.push(' ' + schedule.title)
    }
    return html.join('')
  }

  const getPopupDetailAttendees = (schedule) => {
    return schedule.attendees
  }

  const getPopupDetailBody = (schedule) => {
    const html = []

    if (schedule.isPending) {
      html.push('(Pending)')
    }
    if (schedule.raw && schedule.raw.isCancelled) {
      html.push('(Cancelled)')
    }
    html.push(schedule.body)

    return html.join('\n')
  }

  const goToToday = () => {
    setLoaded(false)
    setCurrentRange({
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek'),
    })
    calendarType === 'bookings'
      ? bookingCalendarRef.current.calendarInstance.today()
      : avalabilityCalendarRef.current.calendarInstance.today()
  }

  const nextWeek = () => {
    setLoaded(false)
    setCurrentRange({
      from: moment(currentRange.from).add(7, 'days'),
      to: moment(currentRange.to).add(7, 'days'),
    })
    calendarType === 'bookings'
      ? bookingCalendarRef.current.calendarInstance.next()
      : avalabilityCalendarRef.current.calendarInstance.next()
  }

  const prevWeek = () => {
    setLoaded(false)
    setCurrentRange({
      from: moment(currentRange.from).subtract(7, 'days'),
      to: moment(currentRange.to).subtract(7, 'days'),
    })
    calendarType === 'bookings'
      ? bookingCalendarRef.current.calendarInstance.prev()
      : avalabilityCalendarRef.current.calendarInstance.prev()
  }

  const loadCalendar = () => {
    setLoaded(true)
    loadEvents(
      currentRange.from.format('YYYY-MM-DD'),
      currentRange.to.format('YYYY-MM-DD')
    )
  }

  const visitClient = (id) => {
    navigate(`/clients/${id}`)
  }

  const addBooking = (day = null, time = null) => {
    //
  }

  const addAvailability = (
    date = moment().format('YYYY-MM-DD'),
    time = { start: '11:00', end: '12:00' }
  ) => {
    setAvailabilityDate(date)
    setAvailabilityData(time)
    setAddAvailabilityFlag(true)
    setModalTitle(`Add Availability Slot`)
    setShowModal(true)
  }

  const deleteAvailabilityClicked = (availability) => {
    setShowModal(false)
    setEditAvailabilityFlag(false)
    setDeleteAvailability(availability)
    setModalTitle(`Delete Availability`)
    setShowModal(true)
  }

  const cancelBookingClicked = (booking) => {
    setShowModal(false)
    setEditBookingFlag(false)
    setViewBookingFlag(false)
    setCancelSession(booking)
    setModalTitle(`Cancel Session`)
    setShowModal(true)
  }

  const editAvailability = (event) => {
    event = { ...event.raw }
    setAvailabilityDate(moment(event.start_time).format('YYYY-MM-DD'))
    event.start = moment(event.start_time).format('HH:mm')
    event.end = moment(event.end_time).format('HH:mm')
    setAvailabilityData(event)
    setEditAvailabilityFlag(true)
    setModalTitle(`Edit Availability`)
    setShowModal(true)
  }

  const editBooking = (event) => {
    let allBookings = []
    event = { ...event.raw }
    setViewBookingDate(moment(event.start_time).format('YYYY-MM-DD'))
    event.start = moment(event.start_time).format('HH:mm')
    event.end = moment(event.end_time).format('HH:mm')
    setViewBookingSlot(event)
    setViewBookingClient(event.client)
    setEditBookingFlag(true)
    setModalTitle(`Edit Session`)
    setShowModal(true)
  }

  const viewBooking = (event) => {
    let allBookings = []
    event = { ...event.raw }
    setViewBookingDate(moment(event.start_time).format('YYYY-MM-DD'))
    event.start = moment(event.start_time).format('HH:mm')
    event.end = moment(event.end_time).format('HH:mm')
    setViewBookingSlot(event)
    setViewBookingClient(event.client)
    setViewBookingFlag(true)
    setModalTitle(`View Session`)
    setShowModal(true)
  }

  const selectTime = (e) => {
    const day = moment(e.start).format('YYYY-MM-DD')
    const start = moment(e.start).format('HH:mm')
    const end = moment(e.end).format('HH:mm')
    if (calendarType === 'bookings') {
      addBooking(day, { start, end })
    }
    if (calendarType === 'availability') {
      addAvailability(day, { start, end })
    }
  }

  const clickEvent = (e) => {
    if (calendarType === 'availability') {
      editAvailability(e.event)
    }

    if (calendarType === 'bookings') {
      editBooking(e.event)
    }
  }

  const nonUserClickEvent = (e) => {
    if (calendarType === 'bookings') {
      viewBooking(e.event)
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    loadEvents(
      currentRange.from.format('YYYY-MM-DD'),
      currentRange.to.format('YYYY-MM-DD'),
      !isChecked
    );
  };

  const ModalBody = () => {
    if (addAvailabilityFlag) {
      return <AddAvailabilityElement />
    }

    if (editAvailabilityFlag) {
      return <EditAvailabilityElement />
    }

    if (deleteAvailability.id) {
      return <DeleteAvailabilityElement />
    }

    if (editBookingFlag) {
      return <EditSessionElement />
    }

    if (viewBookingFlag) {
      return <ViewSessionElement />
    }

    if (cancelSession.id) {
      return <CancelSessionElement />
    }
  }

  const intervals = (start, end) => {
    start = moment(start, 'HH:mm')
    end = moment(end, ' HH:mm')
    start.minutes(Math.ceil(start.minutes() / 15) * 15)

    let result = []

    let current = moment(start)

    while (current <= end) {
      result.push(current.format('HH:mm'))
      current.add(15, 'minutes')
    }

    return result
  }

  const checkTime = (start, end) => {
    setStartTimeValid(true)
    setEndTimeValid(true)
    if (
      moment(end, 'HH:mm').format('HH:mm') <=
      moment(start, 'HH:mm').format('HH:mm')
    ) {
      return false
    }
    return true
  }

  const AddAvailabilityElement = () => {
    const addAvailability = async (e) => {
      e.preventDefault()
      if (!startTimeValid || !endTimeValid) {
        createInfo('error', `The start time must be before the end time`)
        return
      }

      availabilityData.date = availabilityDate
      availabilityData.repeat = availabilityData.repeat || 0

      if (!availabilityData.type) {
        availabilityData.type = availablilityTypes[0];
      }
      setShowLoader(true)
      try {
        const response = await axios.post(
          `/users/${userId}/availability/add`,
          availabilityData
        )
        if (response.data.error === false) {
          const availability = response.data.result
          createInfo('success', `Availability Added`)
          avalabilityCalendarRef.current.calendarInstance.createEvents([
            {
              id: availability.id,
              start: availability.start_time,
              end: availability.end_time,
              attendees: [availability.type.title],
              color: '#fff',
              backgroundColor: '#03a9f4',
              borderColor: '#03a9f4',
              raw: availability,
            },
          ])
          avalabilityCalendarRef.current.calendarInstance.clearGridSelections()
          resetModal()
        }
      } catch (error) { }
      setShowLoader(false)
    }

    const updateAvailabilityServices = (type) => {
      let index = availabilityData.type.indexOf(type.id)
      if (index === -1) {
        availabilityData.push(type.id)
      } else {
        availabilityData.splice(index, 1)
      }
    }

    return (
      <form onSubmit={addAvailability}>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="date"
              name="date"
              id="date"
              defaultValue={availabilityDate}
              onChange={(e) => {
                setAvailabilityDate(e.target.value)
                availabilityData.repeat_until = moment(e.target.value)
                  .add(6, 'months')
                  .format('YYYY-MM-DD')
              }}
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className={`mb-3 ${!startTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="start"
            className="block font-medium text-gray-600 text-sm"
          >
            Start
          </label>
          <select
            defaultValue={availabilityData.start}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(e.target.value, availabilityData.end)
              setStartTimeValid(valid)
              availabilityData.start = e.target.value
            }}
          >
            <option disabled>Select Start Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!startTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className={`mb-3 ${!endTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="end"
            className="block font-medium text-gray-600 text-sm"
          >
            End
          </label>
          <select
            defaultValue={availabilityData.end}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(availabilityData.start, e.target.value)
              setEndTimeValid(valid)
              availabilityData.end = e.target.value
            }}
          >
            <option disabled>Select End Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!endTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className="mt-3 w-full mr-2">
          <h3 className="block font-medium text-gray-600 text-sm">Type</h3>
          <div className="">
            <div className="flex items-center">
              <div className="relative w-full">
                <select
                  type="checkbox"
                  className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  onChange={(e) => availabilityData.type = e.target.value}
                >
                  <option value={0} selected disabled>Select type</option>
                  {availablilityTypes?.map((type) => {
                    return (
                      <option value={JSON.stringify(type)}>{type.title}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <h3 className="block font-medium text-gray-600 text-sm">Repeat</h3>
          <div className=" w-full mr-2 flex items-center">
            <div className="relative mr-2">
              <input
                type="checkbox"
                id="repeat"
                onChange={(e) => {
                  setAvailabilityData({
                    start: availabilityData.start,
                    end: availabilityData.end,
                    type: availabilityData.type,
                    repeat: e.target.checked,
                    repeat_until: availabilityData.repeat_until,
                  })
                }}
                placeholder="Repeat Availability"
                className="field"
                checked={availabilityData.repeat}
              />
            </div>
            <label
              htmlFor="repeat"
              className="block font-medium text-gray-600 text-sm"
            >
              Every Week
              <span className="text-red-400">(for a maximum of 6 months)</span>
            </label>
          </div>
        </div>

        {availabilityData.repeat && (
          <div className="mt-3">
            <label
              htmlFor="date"
              className="block font-medium text-gray-600 text-sm"
            >
              Repeat Until
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="date"
                name="date"
                id="date"
                max={moment(availabilityDate)
                  .add(6, 'months')
                  .format('YYYY-MM-DD')}
                defaultValue={availabilityData.repeat_until}
                onChange={(e) =>
                  (availabilityData.repeat_until = e.target.value)
                }
                min={moment().format('YYYY-MM-DD')}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
        )}

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Add Availability</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const EditAvailabilityElement = () => {
    const updateAvailability = async (e) => {
      e.preventDefault()

      if (!startTimeValid || !endTimeValid) {
        createInfo('error', `The start time must be before the end time`)
        return
      }
      availabilityData.date = availabilityDate

      setShowLoader(true)
      try {
        const response = await axios.post(
          `/availabilities/update/${availabilityData.id}`,
          availabilityData
        )
        if (response.data.error === false) {
          createInfo('success', `Availability Updated`)
          setShowLoader(true)
          loadEvents(
            currentRange.from.format('YYYY-MM-DD'),
            currentRange.to.format('YYYY-MM-DD')
          )
          setShowLoader(false)
          resetModal()
        }
      } catch (error) { }
      setShowLoader(false)
    }

    const updateAvailabilityType = (type) => {
      setAvailabilityData
    }
    return (
      <form onSubmit={updateAvailability}>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="date"
              name="date"
              id="date"
              defaultValue={availabilityDate}
              onChange={(e) => {
                setAvailabilityDate(e.target.value)
                availabilityData.repeat_until = moment(e.target.value)
                  .add(6, 'months')
                  .format('YYYY-MM-DD')
              }}
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className={`mb-3 ${!startTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="start"
            className="block font-medium text-gray-600 text-sm"
          >
            Start
          </label>
          <select
            defaultValue={availabilityData.start}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(e.target.value, availabilityData.end)
              setStartTimeValid(valid)
              availabilityData.start = e.target.value
            }}
          >
            <option disabled>Select Start Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!startTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className={`mb-3 ${!endTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="end"
            className="block font-medium text-gray-600 text-sm"
          >
            End
          </label>
          <select
            defaultValue={availabilityData.end}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(availabilityData.start, e.target.value)
              setEndTimeValid(valid)
              availabilityData.end = e.target.value
            }}
          >
            <option disabled>Select End Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!endTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className="mt-3 w-full mr-2">
          <h3 className="block font-medium text-gray-600 text-sm">Types</h3>
          <div className="flex flex-wrap w-full">
            <select
              defaultValue={availabilityData.availability_type}
              type="checkbox"
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              onChange={(e) => availabilityData.availability_type = e.target.value}
            >
              <option value={0} selected disabled>Select type</option>
              {availablilityTypes?.map((type) => {
                return (
                  <option value={type.id}>{type.title}</option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <p
            className="text-red-500 text-sm cursor-pointer hover:text-red-700"
            onClick={() => deleteAvailabilityClicked(availabilityData)}
          >
            Delete Occurence
          </p>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const EditSessionElement = () => {
    const updateBooking = async (e) => {
      e.preventDefault()
      const data = {
        start_time:
          moment(viewBookingDate).format('YYYY-MM-DD') +
          ' ' +
          viewBookingSlot.start +
          ':00',
        end_time:
          moment(viewBookingDate).format('YYYY-MM-DD') +
          ' ' +
          viewBookingSlot.end +
          ':00',
      }

      if (!startTimeValid || !endTimeValid) {
        createInfo('error', `The start time must be before the end time`)
        return
      }
      viewBookingSlot.date = viewBookingDate
      setShowLoader(true)
      try {
        const response = await axios.post(
          `/sessions/update/booking/${viewBookingSlot.id}`,
          data
        )
        if (response.data.error === false) {
          createInfo('success', `Session Updated`)
          setShowLoader(true)
          loadEvents(
            currentRange.from.format('YYYY-MM-DD'),
            currentRange.to.format('YYYY-MM-DD')
          )
          setShowLoader(false)
          resetModal()
        }
      } catch (error) {
        console.error(error)
      }
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateBooking}>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="date"
              name="date"
              id="date"
              defaultValue={viewBookingDate}
              onChange={(e) => {
                setViewBookingDate(e.target.value)
              }}
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Client
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="client_name"
              id="client_name"
              disabled="disabled"
              defaultValue={viewBookingClient.name}
              onChange={(e) => {
                setViewBookingClient(e.target.value)
              }}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
            <p
              className="text-blue-500 text-sm cursor-pointer hover:text-blue-700 float-right"
              onClick={() => visitClient(viewBookingClient.id)}
            >
              View client
            </p>
          </div>
        </div>

        <div className={`mb-3 ${!startTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="start"
            className="block font-medium text-gray-600 text-sm"
          >
            Start
          </label>
          <select
            defaultValue={viewBookingSlot.start}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(e.target.value, viewBookingSlot.end)
              setStartTimeValid(valid)
              viewBookingSlot.start = e.target.value
            }}
          >
            <option disabled>Select Start Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!startTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className={`mb-3 ${!endTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="end"
            className="block font-medium text-gray-600 text-sm"
          >
            End
          </label>
          <select
            defaultValue={viewBookingSlot.end}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(viewBookingSlot.start, e.target.value)
              setEndTimeValid(valid)
              viewBookingSlot.end = e.target.value
            }}
          >
            <option disabled>Select End Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!endTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        {admin && (
          <div className="w-full flex justify-end">
            <p
              className="text-red-500 text-sm cursor-pointer hover:text-red-700"
              onClick={() => cancelBookingClicked(viewBookingSlot)}
            >
              Cancel Session
            </p>
          </div>
        )}

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const ViewSessionElement = () => {
    return (
      <div>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              readOnly
              type="date"
              name="date"
              id="date"
              defaultValue={viewBookingDate}
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Client
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              readOnly
              type="text"
              name="client_name"
              id="client_name"
              disabled="disabled"
              defaultValue={viewBookingClient.name}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
            <p
              className="text-blue-500 text-sm cursor-pointer hover:text-blue-700 float-right"
              onClick={() => visitClient(viewBookingClient.id)}
            >
              View client
            </p>
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Start
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              readOnly
              type="text"
              name="start"
              id="start"
              defaultValue={viewBookingSlot.start}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            End
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              readOnly
              type="text"
              name="end"
              id="end"
              defaultValue={viewBookingSlot.end}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        {admin && (
          <div className="w-full flex justify-end">
            <p
              className="text-red-500 text-sm cursor-pointer hover:text-red-700"
              onClick={() => cancelBookingClicked(viewBookingSlot)}
            >
              Cancel Session
            </p>
          </div>
        )}

        <div className="flex mt-2 w-full justify-end">
          <button type="button" className="btn mt-4 w-1/2" onClick={resetModal}>
            Close
          </button>
        </div>
      </div>
    )
  }

  const DeleteAvailabilityElement = () => {
    const deleteAvailabilityFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        await axios.get('/availabilities/delete/' + deleteAvailability.id)
        createInfo('error', `Availability Deleted`)
        setShowLoader(true)
        loadEvents(
          currentRange.from.format('YYYY-MM-DD'),
          currentRange.to.format('YYYY-MM-DD')
        )
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteAvailabilityFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this availability?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  useEffect(() => {
    // getCancelReasons()
  }, [])

  const getCancelReasons = async (e) => {
    try {
      const response = await axios.get('/cancel-reasons/all')
      setCancelReasons(response.data.result)
    } catch (error) {
      console.error(error)
    }
    setShowLoader(false)
  }

  const CancelSessionElement = () => {
    const cancelSessionFn = async (e) => {
      e.preventDefault()
      if (!cancelSession.reason_id) {
        createInfo('error', 'Please select a cancellation reason')
        return
      }
      setShowLoader(true)
      resetModal(false)
      try {
        await axios.get(
          '/sessions/delete/' + cancelSession.id + '/' + cancelSession.reason_id
        )
        createInfo('error', `Session Cancelled`)
        setShowLoader(true)
        loadEvents(
          currentRange.from.format('YYYY-MM-DD'),
          currentRange.to.format('YYYY-MM-DD')
        )
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={cancelSessionFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to cancel this session?
          </p>
          <div>
            <p className="pb-4 text-center">
              Make sure to let the client know they will not be able to get this
              session back.
            </p>

            <label
              htmlFor="end"
              className="block font-medium text-gray-600 text-sm"
            >
              Please select a reason for cancelling this booking
            </label>
            <select
              defaultValue="0"
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (cancelSession.reason_id = e.target.value)}
            >
              <option value="0" disabled>
                Select Cancel Reason
              </option>
              {cancelReasons?.map((reason) => {
                return (
                  <option key={reason.id} value={reason.id}>
                    {reason.title}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Yes, cancel this session
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              No
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setAddAvailabilityFlag(false)
    setAvailabilityData({})
    setViewBookingSlot({})
    setAvailabilityDate('')
    setViewBookingDate('')
    setStartTimeValid(true)
    setEndTimeValid(true)

    setEditAvailabilityFlag(false)
    setEditBookingFlag(false)
    setViewBookingFlag(false)

    setShowModal(false)
    setModalTitle('')
  }

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {/* Nav */}
      <div className="mb-4 flex justify-between">
        <div className="flex">
          <button
            className="h-10 px-6 rounded-full border border-slate-300 bg-white text-sm font-medium flex items-center mr-4 hover:bg-slate-300"
            onClick={goToToday}
          >
            Today
          </button>

          <button
            className="h-10 w-10 rounded-full border border-slate-300 bg-white text-sm font-medium flex items-center justify-center mr-2 hover:bg-slate-300"
            onClick={prevWeek}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <button
            className="h-10 w-10 rounded-full border border-slate-300 bg-white text-sm font-medium flex items-center justify-center mr-4 hover:bg-slate-300"
            onClick={nextWeek}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>

          <span className="text-lg h-10 font-medium flex items-center justify-center">
            {moment(currentRange.from).format('DD/MM/YYYY')} -{' '}
            {moment(currentRange.to).format('DD/MM/YYYY')}
          </span>
        </div>

        <div className="flex">
          {calendarType === 'bookings' && userId && (
            <div className="flex items-center">
              <input
                type="checkbox"
                id="cancelledCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label class="ml-1" htmlFor="cancelledCheckbox">Show Cancellations</label>
            </div>
          )}
          {/* {calendarType === 'bookings' && (
            <button
              className="btn ml-3 green"
              onClick={() => addBooking(moment().format('YYYY-MM-DD'))}
            >
              <FontAwesomeIcon icon={faCalendarPlus} className="mr-2" />
              Book
            </button>
          )} */}
          {calendarType === 'availability' && userId && (
            <button
              className="btn ml-3"
              onClick={() => addAvailability(moment().format('YYYY-MM-DD'))}
            >
              <FontAwesomeIcon icon={faCalendarPlus} className="mr-2" />
              Add Availability
            </button>
          )}
          {!loaded && (
            <button className="btn ml-3" onClick={loadCalendar}>
              Load Calendar
            </button>
          )}
        </div>
      </div>
      {calendarType == 'bookings' && (
        <div className="flex items-end space-x-4 mb-4">
          <div className="font-semibold mt-2">Key:</div>
          <div className="flex items-center mt-2">
            <span className="block w-4 h-4 bg-[#2f64a7] rounded-full mr-2"></span>
            <span className="text-sm">Confirmed</span>
          </div>
          <div className="flex items-center mt-2">
            <span className="block w-4 h-4 bg-[#94a3b8] rounded-full mr-2"></span>
            <span className="text-sm">Pending</span>
          </div>
          <div className="flex items-center mt-2">
            <span className="block w-4 h-4 bg-red-400 rounded-full mr-2"></span>
            <span className="text-sm">Cancelled</span>
          </div>
        </div>
      )}
      <div className="w-full h-full rounded-xl shadow-md overflow-hidden relative">
        {!loaded && (
          <div
            onClick={loadCalendar}
            className="absolute cursor-pointer w-full h-full opacity-40 bg-white z-10 flex justify-center items-center"
          >
            <p className="text-2xl xl:text-4xl text-black">
              Click here or 'Load Calendar' to view
            </p>
          </div>
        )}
        <div
          className={`calendar-block ${calendarType === 'availability' ? 'hidden' : ''
            }`}
        >
          <Calendar
            ref={bookingCalendarRef}
            defaultView="month"
            taskView={false}
            disableDblClick={true}
            useCreationPopup={false}
            useDetailPopup={false}
            scheduleView={['time']}
            gridSelection={false}
            week={{
              startDayOfWeek: WEEK_DAY_START,
              hourStart: DAY_START_HOUR,
              hourEnd: DAY_END_HOUR,
              taskView: false,
              eventView: ['time'],
            }}
            template={{
              time: function (schedule) {
                return getTimeTemplate(schedule, false)
              },
              popupEdit: function () {
                return 'Edit'
              },
              popupDetailBody: function (schedule) {
                return getPopupDetailBody(schedule)
              },
            }}
          />
        </div>
        <div
          className={`calendar-block ${calendarType === 'bookings' ? 'hidden' : ''
            }`}
        >
          <Calendar
            style={{ height: '100%', width: '100%' }}
            ref={avalabilityCalendarRef}
            defaultView="week"
            taskView={false}
            disableDblClick={true}
            useCreationPopup={false}
            useDetailPopup={false}
            scheduleView={['time']}
            gridSelection={false}
            week={{
              startDayOfWeek: WEEK_DAY_START,
              hourStart: DAY_START_HOUR,
              hourEnd: DAY_END_HOUR,
              taskView: false,
              eventView: ['time'],
            }}
            template={{
              time: function (schedule) {
                return getTimeTemplate(schedule, false)
              },
              popupEdit: function () {
                return 'More'
              },
              popupDetailBody: function (schedule) {
                return getPopupDetailBody(schedule)
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TuiCalendar
