import MainTitle from '../../components/MainTitle';
import TasksList from './TasksList';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function Tasks() {

  return (
    <section className='text-gray-700'>
      <MainTitle title="Tasks" />
      <TasksList />
    </section>
  );
}

export default Tasks;
