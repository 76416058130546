import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import moment from 'moment';
import Modal from '../../../components/Modal';
import useAuth from '../../../hooks/useAuth';
import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'


const Tasks = ({ tasks, loading = false, filter, refreshTasks, allUsers = null }) => {
  const axios = useAxiosPrivate();
  const { setShowLoader, createInfo, auth } = useAuth()
  const navigate = useNavigate()
  const [allTasks, setAllTasks] = useState(tasks);
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [updateTask, setUpdateTask] = useState(false)
  const [updatedTask, setUpdatedTask] = useState({})
  const [filteredUsers, setFilteredUsers] = useState([{}])
  const [isAssignToOpen, setIsAssignToOpen] = useState(false)

  const statusStyles = {
    // important: 'text-amber-700 bg-amber-200',
    'Due': 'text-green-700 bg-green-200',
    'Overdue': 'text-red-700 bg-red-200',
    'Completed': 'text-blue-700 bg-blue-200',
    'In Progress': 'text-orange-700 bg-orange-200',
  }

  useEffect(() => {
    setFilteredUsers(allUsers)
  }, [allUsers])

  const filterUsers = (handle) => {
    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = allUsers.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString) ||
          e.email.toLowerCase().match(searchString)
        )
      })
      setFilteredUsers(records)
    } else {
      setFilteredUsers(allUsers)
    }
  }

  const determineStatus = (task) => {
    let today = moment();
    let dueDate = moment(task.due_date, 'YYYY-MM-DD');

    if (task.completed || task.status === 'Completed') {
      return 'Completed';
    } else if (dueDate.isBefore(today, 'day')) {
      return 'Overdue';
    } else if (task.status === 'In Progress') {
      return 'In Progress';
    } else {
      return 'Due';
    }
  };

  const filteredTasks = tasks
    .filter((task) => {
      const status = determineStatus(task);
      switch (filter) {
        case 'My Tasks':
          return task.assigned_to && task.assigned_to.id === auth.user.id;
        case 'Due':
          return status === 'Due';
        case 'In Progress':
          return status === 'In Progress';
        case 'Overdue':
          return status === 'Overdue';
        case 'Done':
          return task.completed === 1;
        default:
          return true;
      }
    }).map((task) => ({ ...task, status: determineStatus(task) }));

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleToggleComplete = async (id) => {
    const taskToUpdate = allTasks.find(task => task.id === id);
    if (!taskToUpdate) return;
    if (taskToUpdate.completed === 1) return
    
    const updatedCompletedStatus = !taskToUpdate.completed;
    try {
      const response = await axios.post(`/tasks/completed/${id}`, {
        completed: updatedCompletedStatus ? 1 : 0,
      });
      setAllTasks(prevTasks => prevTasks.map(task =>
        task.id === id ? { ...task, completed: updatedCompletedStatus } : task
      ));
      if(!response.data) {
      }
    } catch (error) {
      console.error('Failed to update task', error);
    }

    refreshTasks();
  };

  const loadCompletedTask = async (task) => {
    try {

      let claim_reference = task.task_info.request_information.claim_reference

      let url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/search/referral?api_token=${process.env.REACT_APP_API_KEY}`;const kisResponse = await axios.post(url, { q: claim_reference });
      let data = kisResponse?.data?.data[0];

      if(data.wbs_case.case_type === 'Staff') {
        const selectedStaff = data.wbs_case.staffs;
        
        const response = await axios.post(`${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/get-org-from-referral/${data.claim_reference}?api_token=${process.env.REACT_APP_API_KEY}`);
        
        if (response.data) {
          let org = response.data.data.org
          let policies = response.data.data.policies
      
          let staffData = {
            'id': selectedStaff.staff_id,
            'name': selectedStaff.name,
            'date_of_birth': selectedStaff.date_of_birth,
            'email': selectedStaff.email,
            'address': selectedStaff.address,
            'school_name': org.name,
            'school_urn': org.urn,
            'policy_id': data.wbs_case.policy_id,
            'policys': policies,
          }
  
          const staffResponse = await axios.post(`clients/exists`, { data: staffData });
          
          setShowLoader(false);
          if(staffResponse.data.result) {
            return `/clients/${staffResponse.data.result}?claim_reference=${data.claim_reference}`;
          }
        }
      
      } else {
        const response = await axios.post(`${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/get-org-from-referral/${data.claim_reference}?api_token=${process.env.REACT_APP_API_KEY}`);
        
        if (response.data) {
          let org = response.data.data.org
          let contacts = response.data.data.contacts
          let policies = response.data.data.policies

          let orgData = {
            'name': org.name,
            'unique_reference': org.urn,
            'address': org.address,
            'contacts': contacts,
            'main_contact' : org.main_contact,
            'policys': policies,
            'type': org.academy_id ? 1 : 2
          }
          const organisationResponse = await axios.post(`organisations/exists`, { data: orgData });

          setShowLoader(false);
          if(organisationResponse.data.result) {
            return `/organisations/${organisationResponse.data.result}?claim_reference=${data.claim_reference}`;
          }
        }
      }
    } catch (error) {
      console.error(error);
      createInfo('error', `Failed to load the task`);
    }
  }

  const updateTaskClicked = async (task) => {
    setShowLoader(true);
    const hasRequest = Boolean(task.task_info?.request_id);

    if (hasRequest) {
      const requestId = task.task_info.request_id;
      let redirectUrl = `/ppq-review/${requestId}/${task.id}`;

      if (task.status === 'Due') {
        task.status = 'In Progress';
        task.assigned_to = task.assigned_to || auth.user.id;
        task.assigned_by = task.assigned_by || auth.user.id;

        await axios.post('/tasks/update', {
          id: task.id,
          status: task.status,
          assigned_to: task.assigned_to,
          assigned_by: task.assigned_by,
        });
      } else if (task.completed === 1 || task.status === 'Completed') {
        redirectUrl = await loadCompletedTask(task);
      }

      setShowLoader(false);
      window.location.href = redirectUrl;

    } else {
      setUpdateTask(true);
      setUpdatedTask(task);
      setModalTitle('Update Task');
      setShowModal(true);
      setShowLoader(false);
    }
  };

  const ModalBody = () => {
    if (updateTask) {
      return <UpdateTaskElement />
    }
  }
  
  const [isAssigning, setIsAssigning] = useState(false)
  const [assigningTo, setAssigningTo] = useState(null)

  const assignTask = async (taskId, userId) => {
    if (isAssigning) return
    setIsAssigning(true)
    setAssigningTo(taskId)
    try {
      const response = await axios.post(`/tasks/assign-task/${taskId}/${userId}`, {});
      if (response.data.error === false) {
        setIsAssignToOpen(false)
        createInfo('success', `Task Assigned`);
        refreshTasks()
      }
    } catch (error) {
      console.error(error.message);
      createInfo('error', `Failed to assign task`);
    } finally {
      setIsAssigning(false)
      setAssigningTo(null)
    }
  }

  const UpdateTaskElement = () => {
    const updatedSelectedTask = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/tasks/update`, {
          id: updatedTask.id,
          title: updatedTask.title,
          due_date: updatedTask.due_date,
          // important: updatedTask.important ? 1 : 0, 
        });

        if (response.data.error === false) {
          createInfo('success', `Task Updated`);
          setUpdatedTask('');
          refreshTasks()
        }
      } catch (error) {
        console.error(error.message);
        createInfo('error', `Failed to update task`);
      }
      resetModal();
      setShowLoader(false);
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUpdatedTask((prevTask) => ({
        ...prevTask,
        [name]: value,
      }));
    };

    // const handleCheckboxChange = (e) => {
    //   setUpdatedTask((prevTask) => ({
    //     ...prevTask,
    //     important: e.target.checked,
    //   }));
    // };

    return (
      <form onSubmit={updatedSelectedTask}>
        <div className="mb-3">
          <label
            htmlFor="title"
            className="block font-medium text-gray-600 text-sm"
          >
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              name="title"
              value={updatedTask.title || ''}
              onChange={handleInputChange}
              type="text"
              id="title"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="due_date"
            className="block font-medium text-gray-600 text-sm"
          >
            Due Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              name="due_date"
              type="date"
              value={updatedTask.due_date || ''}
              onChange={handleInputChange}
              id="due_date"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        {/* <div className="mb-3">
          <label
            htmlFor="important"
            className="block mb-2 font-medium text-gray-600 text-sm"
          >
            Important
          </label>
          <input
            name="important"
            checked={updatedTask.important || false}
            onChange={handleCheckboxChange}
            type="checkbox"
            className="bg-blue-500 focus:accent-blue-500 h-4 w-4"
          />
        </div> */}

        <div className="flex mt-2 w-full space-x-4">
          <button type="submit" className="btn mt-4 w-1/2">Update Task</button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>Cancel</button>
        </div>
      </form>
    );
  };

  const resetModal = () => {
    setUpdateTask(false)
    setShowModal(false)
  }

  return (
    <div className="mb-6 mt-4 space-y-4 text-sm">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {loading ? (
        <div className="border rounded-xl m-1 px-4 py-2 shadow-md flex justify-center items-center space-x-2 cursor-pointer">
          <span>Loading <FontAwesomeIcon icon={faSpinner} className="animate-spin" /></span>
        </div>
      ) : (
        <>
          {filteredTasks.length > 0 ? (
            filteredTasks.map((task) => (
              <div className={`border rounded-xl m-1 px-4 py-2 shadow-md flex items-center space-x-2 cursor-pointer ${task.completed === 1 ? 'bg-violet-200' : ''}`} key={task.id} onClick={(e) => {e.stopPropagation(); updateTaskClicked(task)}}>
                <div className="flex items-center justify-between space-x-2 w-full">
                  <div>
                    <div className={`font-medium ${task.completed === 1 ? 'line-through' : ''}`}>
                      {task.title} {task?.task_info?.request_information?.claim_reference && (
                        <span className="text-xs">
                          ({task?.task_info?.request_information?.claim_reference})
                        </span>
                      )}
                    </div>
                    <div className="text-xs">
                      <FontAwesomeIcon icon={faClock} className="text-gray-400" />{' '}
                      {task.due_date}
                    </div>
                  </div>
                  <div className="text-end flex items-center space-x-2">
                    <div className={classNames( statusStyles[task.status], 'text-xs px-3 py-1 text-center rounded-full capitalize' )}>
                      {task.status}
                    </div>

                    <Menu as="div" className="relative inline-block text-left" open={isAssignToOpen} onClose={() => setIsAssignToOpen(false)}>
                      <MenuButton className={`hover:scale-110 transition duration-300 ease-in-out rounded-full mt-2 mr-2 z-10 group ${task?.assigned_to ? 'bg-' + task?.assigned_to?.profile_colour + '-500' : 'bg-gray-400'}`} onClick={(e) => e.stopPropagation()}>

                        <div className="w-10 h-10 overflow-hidden rounded-full relative">
                          {assigningTo && assigningTo === task.id ? (
                            <div className="w-160 h-10 flex items-center justify-center cursor-pointer transition duration-150 ease-in-out">
                              <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                            </div>
                          ) : (
                            <>
                              {task?.assigned_to ? (
                                <div className="w-10 h-10 flex items-center justify-center cursor-pointer transition duration-150 ease-in-out">
                                  {task.assigned_to.profile_pic.includes('default.png')? (
                                    <p className="text-white">
                                      {task.assigned_to.name.split(' ').map(n => n[0].toUpperCase()).join('')}
                                    </p>
                                  ) : (
                                    <img src={task.assigned_to.profile_pic} alt="User Profile" className="w-12 h-12 rounded-full" />
                                  )}
                                  <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full bg-gray-700 text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100">
                                    {task.assigned_to.name}
                                  </span>
                                </div>
                              ) : (
                                <div className="w-10 h-10 flex items-center justify-center cursor-pointer transition duration-150 ease-in-out pointer-events-none">
                                  <FontAwesomeIcon icon={faUser} className="text-lg" />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {task?.assigned_to && (
                          <span className="absolute bottom-5 left-5 transform translate-x-[-100%] translate-y-full bg-gray-700 text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100">
                            {task.assigned_to.name}
                          </span>
                        )}
                      </MenuButton>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems
                          className="absolute right-0 mt-2 w-[250px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-y-scroll max-h-[400px]"
                          onBlur={() => setTimeout(() => setFilteredUsers(allUsers), 200)}
                        >
                          <div className="p-2">
                            <input
                              type="text"
                              placeholder="Search..."
                              onChange={filterUsers}
                            />
                          </div>
                          <div className="p-2">
                            {filteredUsers?.map((user) => (
                              <div key={user.id} onClick={(e) => {e.stopPropagation(); assignTask(task.id, user.id)}} className="hover:bg-gray-200 rounded-xl px-2 py-1">
                                <div className="flex items-center space-x-4">
                                  <div className={`w-10 h-10 flex items-center justify-center cursor-pointer transition duration-150 ease-in-out rounded-full bg-${user.profile_colour}-500`}>
                                    {user?.profile_pic?.includes('default.png')? (
                                      <p className="text-white">
                                        {user.name.split(' ').map(n => n[0].toUpperCase()).join('')}
                                      </p>
                                    ) : (
                                      <img
                                        src={user.profile_pic}
                                        alt="User Profile"
                                        className="w-10 h-10 rounded-full transition-transform duration-150 ease-in-out transform hover:scale-110"
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <div className="text-sm font-medium text-gray-900">
                                      {user.first_name} {user.last_name}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No tasks found</div>
          )}
        </>
      )}
        
    </div>
  )
};

export default Tasks;
