import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import '../../../viewRequest.css';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faCircleXmark, faChevronCircleUp, faChevronDown, faEdit, faCircleCheck, faFile, faDownload, faPlane, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Modal from '../../../components/Modal'
import ReferralDocuments from './ReferralDocuments';

const ViewRequest = ({ isOpen, onClose, viewingRequest, onRequestUpdate }) => {
  const { setShowLoader, can } = useAuth()
  const [activityLogItems, setActivityLogItems] = useState(null);
  const [requestItems, setRequestItems] = useState(null);
  const [generalNotes, setGeneralNotes] = useState(null);
  const [nurseNotes, setNurseNotes] = useState(null);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [isResultsOpen, setResultsOpen] = useState(false);
  const [isGeneralNotesOpen, setGeneralNotesOpen] = useState(false);
  const [isNurseNotesOpen, setNurseNotesOpen] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [activeTab, setActiveTab] = useState("Details")
  const [chargeable, setChargeable] = useState(false)
  const [canSeeNurseNotes, setCanSeeNurseNotes] = useState(can('view-nurse-notes'))
  const axios = useAxiosPrivate();
  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [ohModal, setOhModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const isReopen = viewingRequest?.claim_status === 'paid' || viewingRequest?.claim_status === 'withdrawn';

  useEffect(() => {
    if (viewingRequest?.service_request?.activity_log) {
      setActivityLogItems(JSON.parse(viewingRequest.service_request?.activity_log));
    }
    if (viewingRequest?.service_request?.request_information) {
      setRequestItems(JSON.parse(viewingRequest.service_request?.request_information));
    }

    if(viewingRequest?.service_request) {
      let requestId = viewingRequest?.service_request?.id
      let url = `/claims/get-notes/${requestId}`

      axios.get(url, {nurse: canSeeNurseNotes}).then((res) => {
        if(res.data.length === 0) {
          setGeneralNotes([])
          setNurseNotes([])
        } else {
          setGeneralNotes(res.data.filter(note => note.note_type_id === 1))
          setNurseNotes(res.data.filter(note => note.note_type_id === 2))
        }
      })
    }
  }, [viewingRequest]);

  const toggleMoreDetails = () => setShowMoreDetails(!showMoreDetails);

  const statusClass = (serviceName) => {
    if (!serviceName) return "bg-gray-200 text-gray-800";
    const lowerName = serviceName.toLowerCase();

    if (lowerName.includes("expired") || lowerName.includes("deleted") || lowerName.includes("incomplete")) {
      return "bg-rose-200 text-rose-800 border-rose-400";
    }

    if (lowerName.includes("no barriers") || lowerName.includes("complete") || lowerName.includes("in house") || lowerName.includes("chargeable") || lowerName.includes("not chargeable")) {
      return "bg-green-200 text-green-800 border-green-400";
    }
    if (lowerName.includes("waiting") || lowerName.includes("follow up") || lowerName.includes("in progress") || lowerName.includes("settle")) {
      return "bg-amber-200 text-amber-800 border-amber-400";
    }

    if (lowerName.includes("acute") || lowerName.includes("gp helpline")) {
      return "bg-blue-200 text-blue-800 border-blue-400";
    }

    if (lowerName.includes("weight management") || lowerName.includes("physiotherapy")) {
      return "bg-teal-200 text-teal-800 border-teal-400";
    }

    if (lowerName.includes("menopause") || lowerName.includes("june")) {
      return "bg-pink-200 text-pink-800 border-pink-400";
    }

    if (lowerName.includes("other") || lowerName.includes("mindful")) {
      return "bg-orange-200 text-orange-800 border-orange-400";
    }

    return "bg-gray-200 text-gray-800";
  };

  // Handle file download
  const handleDownload = async () => {
    try {
      let fileName = requestItems.filename.replace('/pdf/oh-result/', '');
      window.open(`${process.env.REACT_APP_KIS_BASE_URL}/download-decrypted-file/` + fileName, '_blank').focus();
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };



  const downloadResults = async () => {
    try {
      let fileName = requestItems.filename.replace('/pdf/oh-result/', '');
      // window.open(`${process.env.REACT_APP_KIS}/download-decrypted-file/` + fileName, '_blank').focus();
      window
        .open(
          `/download-decrypted-file/claim/` +
          viewingRequest.claim_reference,
          '_blank'
        )
        .focus()
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };


  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
    setResultsOpen(false);
    setGeneralNotesOpen(false);
    setNurseNotesOpen(false);
  };

  const toggleResults = () => {
    setResultsOpen(!isResultsOpen);
    setAccordionOpen(false);
    setGeneralNotesOpen(false);
    setNurseNotesOpen(false);
  };

  const toggleGeneralNotes = () => {
    setGeneralNotesOpen(!isGeneralNotesOpen);
    setAccordionOpen(false);
    setResultsOpen(false);
    setNurseNotesOpen(false);
  };

  const toggleNurseNotes = () => {
    setNurseNotesOpen(!isNurseNotesOpen);
    setAccordionOpen(false);
    setResultsOpen(false);
    setGeneralNotesOpen(false);
  };

  const openWithdraw = () => {
    setWithdraw(true);
    setShowModal(true);
    setModalTitle("Withdraw this referral?");
  }

  const showOhResultClicked = () => {
    setShowModal(true);
    setModalTitle("Send OH Result Form");
    setOhModal(true);
  }

  const handleAction = () => {
    if (isReopen) {
      setModalTitle("Would you like to reopen this referral?");
    } else {
      setModalTitle(`Are you sure you want to complete ${viewingRequest.claim_reference}?`);
    }
    setCompleted(true);
    setShowModal(true);
  };

  const ModalBody = () => {
    if (withdraw) {
      return <WithdrawReferralElement />
    }

    return <CompleteOrReopenReferralElement />
  }

  const resetModal = () => {
    setShowModal(false);
    setCompleted(false);
    setOhModal(false);
    setWithdraw(false);
    setModalTitle('');
  };

  const CompleteOrReopenReferralElement = () => {
    const handleSubmit = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        let response;
        if (isReopen) {
          response = await axios.post(`${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/case/claim-reopen/${viewingRequest.id}?api_token=${process.env.REACT_APP_API_KEY}`);
        } else {
          response = await axios.post(`${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/case/claim-complete/${viewingRequest.id}?api_token=${process.env.REACT_APP_API_KEY}`);
        }

        if (response.data) {
          resetModal();
          onRequestUpdate()
        }
      } catch (err) {
        console.error("Error processing request:", err);
      } finally {
        setShowLoader(false);
      }
    };

    return (
      <form>
        <div className="mb-3">
          {isReopen ? "Would you like to reopen this referral?" : "Would you like to mark the referral as paid"}
        </div>
        <div className="flex mt-2 w-full">
          <button onClick={handleSubmit} type="button" className="btn mt-4 mr-4 w-1/2">
            {isReopen ? "Reopen" : "Complete"}
          </button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>
            Cancel
          </button>
        </div>
      </form>
    );
  };

  const WithdrawReferralElement = () => {
    const handleSubmit = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        let response = await axios.post(`${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/case/claim-withdraw/${viewingRequest.id}?api_token=${process.env.REACT_APP_API_KEY}`);
        if (response.data) {
          resetModal();
          onRequestUpdate();
        }
      } catch (err) {
        console.error("Error processing request:", err);
      } finally {
        setShowLoader(false);
      }
    };

    return (
      <form>
        <div className="mb-3">
          Are you sure you want to withdraw this referral
        </div>
        <div className="flex mt-2 w-full">
          <button onClick={handleSubmit} type="button" className="btn mt-4 mr-4 w-1/2">
            Withdraw
          </button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>
            Cancel
          </button>
        </div>
      </form>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <div className='p-4 flex text-gray-700 space-x-2'>
            <div className='w-1/2 flex-shrink-0 text-sm border rounded-lg p-4 overflow-hidden'>
              <h2 className='text-base font-semibold text-gray-700 mb-4'>Details</h2>
              <div className='space-y-2'>
                <div><p><strong>Title: </strong> Occupational Health Query </p></div>
                <div>
                  <p className='w-96 break-words overflow-hidden'>
                    <strong>Description: </strong> {viewingRequest?.contact_description || ''}
                  </p>
                </div>
                <div><p><strong>Contact Type: </strong> {viewingRequest?.contact_type?.name || ''}</p></div>
                <div>
                  <p><strong>Service Code: </strong>
                    {viewingRequest?.case_service?.wbs_service?.service_code || ''}
                  </p>
                </div>
                <div>
                  {viewingRequest?.service_request?.length > 0 ? (
                    <p>
                      <strong>Status: </strong>
                      <span
                        className={`${statusClass(viewingRequest?.case_service?.wbs_service?.service_name)} rounded-full px-2 py-1 capitalize font-medium text-sm`}
                      >
                        {viewingRequest?.case_service?.wbs_service?.service_name || ''}
                      </span>
                    </p>
                  ) : (
                    <p>
                      <strong>Service: </strong>
                      <span
                        className={`${statusClass(viewingRequest?.case_service?.wbs_service?.service_name)} rounded-full px-2 py-1 capitalize font-medium text-sm`}
                      >
                        {viewingRequest?.case_service?.wbs_service?.service_name || ''}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className='w-1/2 flex-shrink-0'>
              {viewingRequest?.service_request && (
                <div className='text-sm border rounded-lg p-4 overflow-y-scroll'>
                  <h2 className='text-base font-semibold text-gray-700 mb-4'>PPQ Details</h2>
                  <div className='space-y-2'>
                    <div><p><strong>Title: </strong> Occupational Health Query </p></div>
                    <div><p><strong>Description: </strong> {viewingRequest?.service_request?.description || 'N/A'}</p></div>
                    <div>
                      <p><strong>Status: </strong>
                        <span className={`${statusClass(viewingRequest?.service_request?.request_status)} rounded-full px-2 py-1 capitalize font-medium text-sm`}>
                          {viewingRequest?.service_request?.request_status || ''}
                        </span>
                      </p>
                    </div>
                    <div className='grid grid-cols-2 gap-x-6 gap-y-2'>
                      {viewingRequest?.service_request?.request_information ? (
                        Object.entries(JSON.parse(viewingRequest?.service_request?.request_information).staff_details).map(([key, value]) => (
                          <div key={key} className=''>
                            <strong className='capitalize'>{key.replace(/_/g, ' ')}:</strong>{' '}
                            {key.toLowerCase().includes('date') && value ? moment(value).format('DD/MM/YYYY') : value}
                          </div>
                        ))
                      ) : (
                        'Not Found'
                      )}
                    </div>

                    {/* File Download Section */}
                    {requestItems && requestItems?.filename && (
                      <div className='flex items-center justify-between border border-teal-400 bg-teal-50 rounded-md px-4 py-2 text-sm'>
                        <div className='flex items-center'>
                          <FontAwesomeIcon icon={faFile} className="h-4 w-4 text-[#02ADAD] pr-2" />
                          <div className='text-gray-800'>PPQ Results Document</div>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="text-[#02ADAD] hover:text-teal-700 cursor-pointer"
                            onClick={handleDownload}
                          />
                        </div>
                      </div>
                    )}

                    {/* Activity Log Section */}
                    <div>
                      <p className="mb-4 flex items-center">
                        <strong>Activity Log</strong>
                        <button onClick={toggleAccordion} className="ml-2 text-indigo-900 hover:underline">
                          <FontAwesomeIcon icon={isAccordionOpen ? faChevronCircleUp : faChevronCircleDown} className="h-4 w-4 focus:border-none" />
                        </button>
                      </p>
                      {isAccordionOpen && (
                        <div className="pl-2 text-sm">
                          {activityLogItems && (Array.isArray(activityLogItems) ? activityLogItems.length > 0 : Object.keys(activityLogItems).length > 0) ? (
                            <ol className="relative border-s">
                              {(Array.isArray(activityLogItems) ? activityLogItems : [activityLogItems]).map((log, index) => (
                                <li className="mb-6 flex items-center" key={index}>
                                  <span className="absolute flex items-center justify-center w-5 h-5 bg-[#02ADAD] rounded-full -start-3 ring-4 ring-white"></span>
                                  <div className="ml-6">
                                    <h3 className="mb-1  font-semibold text-gray-900">{log.title}</h3>
                                    <time className="block mb-2 text-xs font-normal leading-none text-gray-400">
                                      {moment(log.timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                    </time>
                                  </div>
                                </li>
                              ))}
                            </ol>
                          ) : (
                            <ol className="relative border-s text-sm">
                              <li className="mb-10 ms-6">
                                <span className="absolute flex items-center justify-center w-5 h-5 bg-[#02ADAD] rounded-full -start-3 ring-4 ring-white"></span>
                                <h3 className="flex items-center mb-1 font-semibold text-gray-900">No Activity Log Data</h3>
                                <time className="block mb-2 text-xs font-normal leading-none text-gray-400">{moment().format('DD/MM/YYYY HH:mm:ss')}</time>
                              </li>
                            </ol>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Questionnaire Results Section */}
                    <div>
                      <p className="mb-4 flex items-center">
                        <strong>Questionnaire Results</strong>
                        <button onClick={toggleResults} className="ml-2 text-indigo-900 hover:underline">
                          <FontAwesomeIcon icon={isResultsOpen ? faChevronCircleUp : faChevronCircleDown} className="h-4 w-4 focus:border-none" />
                        </button>
                      </p>
                      {isResultsOpen && (
                        <div className="pl-2 text-sm h-fit">
                          <div className="grid grid-cols-2 gap-4">
                            {viewingRequest?.service_request?.request_information ? (
                              Object.values(JSON.parse(viewingRequest?.service_request?.request_information).form_data)
                                .sort((a, b) => a.order - b.order)
                                .map((field, index) => (
                                  <div key={index} className="flex flex-col">
                                    <strong className="capitalize">{field.title}:</strong>
                                    <span>
                                      {field.title.toLowerCase().includes('date') && field.value
                                        ? moment(field.value, 'DD/MM/YYYY').format('DD/MM/YYYY')
                                        : (field.value === true || field.value === "true") ? (
                                          <span className="text-green-600 font-semibold">True</span>
                                        ) : (field.value === false || field.value === "false") ? (
                                          <span className="text-red-600 font-semibold">False</span>
                                        ) : field.value?.toLowerCase() === 'yes' || field.value?.toLowerCase() === 'no' ? (
                                          <span className={field.value.toLowerCase() === 'yes' ? 'text-green-600 font-semibold' : 'text-red-600 font-semibold'}>
                                            {field.value}
                                          </span>
                                        ) : field.value || 'N/A'}
                                    </span>
                                  </div>
                                ))
                            ) : (
                              <p>Not Found</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Questionnaire General Notes Section */}
                    <div>
                      <p className="mb-4 flex items-center">
                        <strong>General Notes</strong>
                        <button onClick={toggleGeneralNotes} className="ml-2 text-indigo-900 hover:underline">
                          <FontAwesomeIcon icon={isGeneralNotesOpen ? faChevronCircleUp : faChevronCircleDown} className="h-4 w-4 focus:border-none" />
                        </button>
                      </p>
                      {isGeneralNotesOpen && (
                        <div className="pl-2 text-sm h-fit">
                          <div className="grid gap-4">
                            {generalNotes && generalNotes?.length > 0 ? (
                              <div className="space-x-2">
                                {/* Regular Notes */}
                                <div className="w-full">
                                  {generalNotes.filter(note => note.note_type_id === 1).map((note) => (
                                    <div key={note?.id} className="w-full flex justify-between p-2 mb-4 border-b border-gray-300">
                                      <div>
                                        <p className="text-sm text-black mb-2">"{note?.request_note}"</p>
                                        <p className="text-gray-500 ml-2"> - {note?.created_by?.name}<span className="font-normal"> ({moment(note?.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD/MM/YYYY HH:mm:ss')})</span></p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <p>No Notes</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Questionnaire Nurse Notes Section */}
                    <div>
                      <p className="mb-4 flex items-center">
                        <strong>Nurse Notes</strong>
                        <button onClick={toggleNurseNotes} className="ml-2 text-indigo-900 hover:underline">
                          <FontAwesomeIcon icon={isNurseNotesOpen ? faChevronCircleUp : faChevronCircleDown} className="h-4 w-4 focus:border-none" />
                        </button>
                      </p>
                      {canSeeNurseNotes && isNurseNotesOpen && (
                        <div className="pl-2 text-sm h-fit">
                          <div className="grid gap-4">
                            {nurseNotes && nurseNotes?.length > 0 ? (
                              <div className="space-x-2">
                                {/* Nurse Notes */}
                                <div className="w-full">
                                  {nurseNotes.filter(note => note.note_type_id === 2).map((note) => (
                                    <div key={note?.id} className="w-full flex justify-between p-2 mb-4 border-b border-gray-300">
                                      <div>
                                        <p className="text-sm text-black mb-2">"{note?.request_note}"</p>
                                        <p className="text-gray-500 ml-2"> - {note?.created_by?.name}<span className="font-normal"> ({moment(note?.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD/MM/YYYY HH:mm:ss')})</span></p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <p>No Notes</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      case "Documents":
        return (
          <div className='p-4 text-gray-700 m-4'>
            <ReferralDocuments viewingRequest={viewingRequest} />
          </div>
        );
      case "Oh Result Form":
        return (
          <div className="p-4">
            <a
              href={`${process.env.REACT_APP_KIS_BASE_URL}/download-decrypted-file/${viewingRequest?.request_information?.filename}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Click here to download the result
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog static as="div" className="relative z-[100]" onClose={onClose}>
          {showModal && (
            <Modal title={modalTitle} body={<ModalBody />} show={resetModal} width={ohModal ? '700px' : '550px'} />
          )}
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex w-2/3 pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen transform transition duration-500 ease-in-out sm:duration-700">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-[#02ADAD] px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-2xl font-semibold leading-6 text-white">
                            Reference: {viewingRequest?.claim_reference || ''}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button type="button" className="relative text-teal-100 hover:text-white" onClick={onClose}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <FontAwesomeIcon icon={faCircleXmark} className="h-6 w-6 focus:border-none" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Tab navigation */}
                      <div >
                        <ul className='flex items-center space-x-2 p-4 text-sm'>
                          <li
                            className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white ${activeTab === 'Details' ? 'bg-nav text-white' : 'bg-gray-200 text-gray-700'}`}
                            onClick={() => setActiveTab('Details')}
                          >
                            Details
                          </li>
                          <li
                            className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white ${activeTab === 'Documents' ? 'bg-nav text-white' : 'bg-gray-200 text-gray-700'}`}
                            onClick={() => setActiveTab('Documents')}
                          >
                            <FontAwesomeIcon icon={faFile} className="h-4 w-4 focus:border-none" /> Documents
                          </li>
                          { !viewingRequest?.service_request && (
                            <li
                              className="rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white bg-gray-200 text-gray-700"
                              onClick={handleAction}
                            >
                              <div className='flex items-center space-x-2'>
                                <FontAwesomeIcon icon={faCircleCheck} className="h-4 w-4 focus:border-none" />
                                <span>{isReopen ? "Reopen" : "Complete"}</span>
                              </div>
                            </li>
                          )}
                          {viewingRequest?.claim_status !== "withdrawn" && (
                            <li onClick={openWithdraw}
                              className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white bg-gray-200 text-gray-700`}
                            >
                              Withdraw
                            </li>
                          )}
                          {viewingRequest?.service_request?.length > 0 && (
                            <li
                              className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white bg-gray-200 text-gray-700`}
                              onClick={downloadResults}
                            >
                              <FontAwesomeIcon icon={faDownload} className="h-4 w-4 focus:border-none" /> Download Result
                            </li>
                          )}
                          {/* <li 
                          className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white bg-gray-200 text-gray-700`} 
                          onClick={() => showOhResultClicked()}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} className="h-4 w-4 focus:border-none" /> OH Result Form
                        </li> */}
                        </ul>
                      </div>
                      {/* Tab content */}
                      {renderContent()}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ViewRequest;


