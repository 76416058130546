import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const RequestNotes = ({ generalNotes, nurseNotes, isNurse = false, deleteNote }) => {
  const axios = useAxiosPrivate();

  return (
    <div className="w-full">
      <div className="bg-white rounded-xl p-4 mb-8 shadow-md">
        <h2 className="font-bold text-xl mb-2">General Notes</h2>
        {generalNotes && generalNotes.map((note) => (
          <div key={note?.id} className="w-full flex justify-between p-4 mb-8 border-b border-gray-300">
            <div>
              <p className="text-sm text-gray-600 font-semibold mb-1">{note?.created_by?.name} - {moment(note?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
              <p className="text-sm text-gray-600">{note?.request_note}</p>
            </div>
            <div>
              <button className="text-red-500 hover:text-red-700" onClick={() => deleteNote(note)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
        {!generalNotes && (
          <div className="w-full rounded-xl shadow-md mb-8">
            <p className="text-sm text-gray-600">No notes yet</p>
          </div>
        )}
      </div>
      {isNurse && (
        <div className="bg-white rounded-xl p-4 shadow-md mb-8">
          <h2 className="font-bold text-xl mb-2">Nurse Notes</h2>
          {nurseNotes && nurseNotes.map((note) => (
            <div key={note?.id} className="w-full flex justify-between p-4 mb-8 border-b border-gray-300">
              <div>
                <p className="text-sm text-gray-600 font-semibold mb-1">{note?.created_by?.name} - {moment(note?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
                <p className="text-sm text-gray-600">{note?.request_note}</p>
              </div>
              <div>
                <button className="text-red-500 hover:text-red-700" onClick={() => deleteNote(note)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))}
          {!nurseNotes && (
            <div className="w-full rounded-xl shadow-md p-4 mb-8">
              <p className="text-sm text-gray-600">No notes yet</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
};

export default RequestNotes;
