import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { useParams, useNavigate, Navigate } from "react-router-dom";
import {
  faCircleXmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import PossibleMatches from "./PossibleMatchCards";

const ViewEnquirySlideOut = ({ open, setOpen, enquiry }) => {
  const { id } = useParams();
  const { setShowLoader, createInfo, can } = useAuth();
  // search
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  // No of Records to be displayed on each page
  const [showMatches, setShowMatches] = useState(false);
  const [statuses, setStatuses] = useState({});
  const [enquiryData, setEnquiryData] = useState({});
  const [localMatches, setLocalMatches] = useState({});
  const [kisMatches, setKISMatches] = useState({});
  const [checkedForMatches, setCheckedForMatches] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [linkClient, setLinkclient] = useState(true);
  const [enquiryClient, setEnquiryClient] = useState({});
  const [editEnquiry, setEditEnquiry] = useState(null);
  const [enquiryStatus, setEnquiryStatus] = useState({});

  const goToEnquiry = () => {
    navigate(`/enquiries`);
  };

  useEffect(() => {
    if (enquiry) {
      setEnquiryClient(enquiry.client)
      setEnquiryStatus(enquiry.statuses)
      if (enquiry.info) {
        try {
          const decodedInfo = JSON.parse(enquiry.info)
          setEnquiryData(decodedInfo)
        } catch (error) {
          console.error("Error decoding enquiry info:", error)
        }
      }
    }
  }, [enquiry]);

  const getStatuses = async () => {
    try {
      await axios.get("/enquiries/statuses").then((response) => {
        setStatuses(response.data.result);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = () => {
    setEditMode((prevState) => !prevState);
    setEditEnquiry({ ...enquiryData });
  };

  const getKISClientMatches = async (data) => {
    const kisMatchesRequest = await axios.post(
      `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/search/staff?api_token=${process.env.REACT_APP_API_KEY}`, { q: enquiryData.name }
    ).then((response) => {

      if (response.data !== "") {
        let result = response.data.data

        for (let i = 0; result.length > i; i++) {
          result[i].isKIS = 1;
        }

        if (result.length > 0) {
          setKISMatches(result)
          setLinkclient(false)
          setShowMatches(true)
        }
      } else {
        setLinkclient(false)
      }

      setShowLoader(false);
      setCheckedForMatches(true);
    });
  }

  const getLocalClientMatches = async (data) => {

    const localMatchesRequest = await axios.post("/clients/matches", data).then((response) => {
      let result = response.data.result;

      if (result.length > 0) {
        setLocalMatches(result)
        setLinkclient(false)
        setShowMatches(true)
      } else {
        setLinkclient(false)
      }

      setShowLoader(false);
      setCheckedForMatches(true);
    });

  }

  const getClientMatches = async () => {
    setShowLoader(true);
    let data = {
      name: enquiryData.name,
      email: enquiryData.info.email,
    };

    getLocalClientMatches(data);
    getKISClientMatches(data);
  };

  const viewClient = () => {
    navigate(`/clients/` + enquiryData.client.id)
  }

  const saveClient = async () => {
    setShowLoader(true);

    let data = {
      enquiry_id: enquiryData.id,
      client_id: selectedClient.id,
    };

    if (selectedClient.isKIS) {
      data['is_kis'] = selectedClient.isKIS;
      data['policy_id'] = selectedClient.policy_id;
      data['email'] = selectedClient.email;
      data['date_of_birth'] = selectedClient.date_of_birth;
      data['name'] = selectedClient.name;
      data['id'] = selectedClient.id;
      data['school_urn'] = selectedClient.school_urn;
      data['school_name'] = selectedClient.school_name;
      data['org_type'] = selectedClient.org_type;
    }

    const response = await axios.post("/enquiries/attach/client", data);

    if (response.data.error === true) {
      setShowLoader(false);
      createInfo("error", "failed to link client, please try again");
      return;
    }
    setEnquiryClient({ ...response.data.result, enquiryClient });
    setShowMatches(false);
    setCheckedForMatches(false)
    setEnquiryStatus(response.data.result.statuses);
    setShowLoader(false);
    setSelectedClient(null);
    createInfo("success", "Successfully linked client to enquiry");
  };

  const updateEnquiry = async () => {
    setShowLoader(true);
    setEditMode(false);

    const notes = {
      info: { additionalNotes: editEnquiry.info.additionalNotes }
    };

    try {
      const response = await axios.post(`/enquiries/update/` + enquiry.id, notes)

      if (response.data.error) {
        setShowLoader(false)
        createInfo("error", "Failed to update enquiry, please try again")
        return
      }

      const updatedAdditionalNotes = JSON.parse(response.data.result.info).additionalNotes

      setEnquiryData((prevData) => ({
        ...prevData,
        info: {
          ...prevData.info,
          additionalNotes: updatedAdditionalNotes,
        },
      }))

      createInfo("success", "Successfully updated enquiry")
    } catch (error) {
      createInfo("error", "Failed to update enquiry, please try again")
    } finally {
      setShowLoader(false)
    }
  };

  const sendCreateClientData = () => {
    return navigate(`/clients/new`, { state: { enquiry_data: enquiryData } })
  }

  useEffect(() => {
    getStatuses();
  }, []);

  const selectClient = (client) => {
    if (selectedClient && client.id === selectedClient.id) {
      setSelectedClient(null);
      return;
    }

    setSelectedClient(client);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl relative">
                    <div className="bg-nav px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <Dialog.Title className="text-lg font-semibold leading-6 text-white">
                            Viewing Enquiry: {enquiry?.name}
                          </Dialog.Title>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-nav text-white hover:text-nav-dark focus:outline-none focus:ring-2 focus:ring-nav"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faCircleXmark} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <section className="p-4">
                      <div className="flex justify-around space-x-4 max-w-7xl mx-auto">
                        <div className="mb-8 w-full">
                          <div className="flex flex-col items-center">
                            <div className=" rounded-lg border p-4 w-full mb-8">
                              <h2 className="text-slate-800 font-bold mb-4">
                                Service Details
                              </h2>
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <label htmlFor="" className="text-slate-800 font-semibold">
                                    Name
                                  </label>
                                  <p className="text-md text-violet-800">
                                    {" "}
                                    {enquiryData?.service?.name}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className=" rounded-lg border p-4 w-full mb-8">
                              <div className="float-right">
                                <FontAwesomeIcon
                                  title="Edit Enquiry Details"
                                  onClick={handleEditClick}
                                  icon={faPenToSquare}
                                  className={`text-xl text-violet-500 hover:text-violet-800 cursor-pointer ${editMode ? "text-violet-500" : ""
                                    }`}
                                />
                              </div>
                              <h2 className="text-slate-800 font-bold mb-4">
                                Enquiry Details
                              </h2>
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <label htmlFor="" className="text-slate-800 font-semibold">
                                    Name
                                  </label>
                                  <p className="text-md text-violet-800">
                                    {" "}
                                    {enquiry?.name}
                                  </p>
                                </div>
                                <div>
                                  <label className="text-slate-800 font-semibold" htmlFor="">
                                    Email Address
                                  </label>
                                  <p className="text-md text-violet-800">
                                    {" "}
                                    {enquiryData?.email}
                                  </p>
                                </div>
                                <div>
                                  <label className="text-slate-800 font-semibold" htmlFor="">
                                    Date of Birth
                                  </label>
                                  <p className="text-md text-violet-800">
                                    {" "}
                                    {moment(enquiryData?.dateOfBirth).format('DD/MM/YYYY')}
                                  </p>
                                </div>
                                <div>
                                  <label className="text-slate-800 font-semibold" htmlFor="">
                                    Contact Number
                                  </label>
                                  <p className="text-md text-violet-800">
                                    {" "}
                                    {enquiryData?.contactNumber}
                                  </p>
                                </div>
                                <div>
                                  <label className="text-slate-800 font-semibold" htmlFor="">
                                    School Name
                                  </label>
                                  <p className="text-md text-violet-800">
                                    {" "}
                                    {enquiryData?.schoolName}
                                  </p>
                                </div>
                                <div>
                                  <label className="text-slate-800 font-semibold" htmlFor="">
                                    School Postcode
                                  </label>
                                  <p className="text-md text-violet-800">
                                    {" "}
                                    {enquiryData?.schoolPostcode}
                                  </p>
                                </div>
                                <div>
                                  <label className="text-slate-800 font-semibold" htmlFor="">
                                    Contact Time
                                  </label>
                                  <p className="text-md text-violet-800">
                                    {" "}
                                    {enquiryData?.contactTime}
                                  </p>
                                </div>

                                {!editMode ? (
                                  <div>
                                    <label className="text-slate-800 font-semibold">
                                      Additional Notes
                                    </label>
                                    <p className="text-md text-violet-800">
                                      {enquiryData?.info?.additionalNotes}
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <label className="text-slate-800 font-semibold">
                                      Additional Notes
                                    </label>
                                    <textarea
                                      defaultValue={editEnquiry?.info?.additionalNotes}
                                      onChange={(e) => {
                                        setEditEnquiry((prev) => ({
                                          ...prev,
                                          info: { ...prev.info, additionalNotes: e.target.value },
                                        }));
                                      }}
                                      className=" border border-nav-dark text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                                    />
                                  </div>
                                )}
                              </div>

                              <div className="mt-8 space-y-4">
                                <div className="flex items-center">
                                  <input
                                    type="checkbox"
                                    id="shielding"
                                    disabled
                                    checked={enquiry?.info?.shielding === "on" ? 1 : 0}
                                    className="border border-nav-dark text-gray-900 text-sm rounded-xl focus:ring-violet-500 focus:border-violet-500 block p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                                  />
                                  <label
                                    className="pl-4 text-slate-800 font-medium"
                                    htmlFor=""
                                  >
                                    Is the staff member still working?
                                  </label>
                                </div>


                                <div className="max-w-3xl mx-auto mt-4">
                                  {editMode && (
                                    <button className={"btn primary float-right"} onClick={updateEnquiry}>
                                      Update
                                    </button>
                                  )}
                                </div>
                              </div>

                              <div className="mt-8"></div>
                            </div>

                            <div className="rounded-lg border p-4 w-full">
                              <h2 className="text-slate-800 font-bold mb-4">
                                Referred Details
                              </h2>
                              <div>
                                <label className="text-slate-800 font-semibold" htmlFor="">
                                  Status
                                </label>
                                <div>
                                  {editMode ? (
                                    <div>
                                      <select
                                        className="px-2 py-2 block w-full rounded-md shadow-m"
                                        defaultValue={JSON.stringify(enquiryData.statuses)}
                                        onChange={(e) =>
                                          (editEnquiry.statuses = e.target.value)
                                        }
                                      >
                                        {statuses?.map((status) => {
                                          return (
                                            <option
                                              key={status.id}
                                              value={JSON.stringify(status)}
                                            >
                                              {status.title}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  ) : (
                                    <div>
                                      <div>
                                        <p className={`bg-${enquiryStatus?.colour}-200 text-${enquiryStatus?.colour}${enquiryStatus?.colour === 'orange' ? '-800' : '-700'} rounded-full py-1 px-2 capitalize text-center w-1/2`}>
                                          {enquiryStatus?.title}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-8 w-full">
                          <div className="">
                            <div className="rounded-lg border p-4">
                              <h2 className="text-slate-800 font-bold mb-4">
                                Client Details
                              </h2>
                              {enquiryClient !== null ? (
                                <div className="space-y-2">
                                  <div>
                                    <label htmlFor="" className="text-slate-800 font-semibold">
                                      Name
                                    </label>
                                    <p className="text-md text-violet-800">
                                      {enquiryClient.client
                                        ? enquiryClient?.client?.name
                                          ? enquiryClient?.client?.name
                                          : enquiry?.client?.name
                                        : enquiry?.client?.name}
                                    </p>
                                  </div>
                                  <div>
                                    <label htmlFor="" className="text-slate-800 font-semibold">
                                      Email
                                    </label>
                                    {enquiryClient.client ? (

                                      <p className="text-md text-violet-800">
                                        {enquiryClient.client.email
                                          ? enquiryClient.client.email : ''}
                                      </p>

                                    ) : (

                                      <>
                                        {enquiry?.client && (
                                          <p className="text-md text-violet-800">
                                            {enquiry?.client?.email ?
                                              enquiry?.client?.email : ''}
                                          </p>
                                        )}
                                      </>

                                    )
                                    }
                                  </div>
                                  <div>
                                    <label htmlFor="" className="text-slate-800 font-semibold">
                                      Status
                                    </label>
                                    <p className={`bg-${enquiry?.statuses.colour}-200 text-${enquiry?.statuses.colour}${enquiry?.statuses.colour === 'orange' ? '-800' : '-700'} rounded-full py-1 px-2 capitalize text-center w-1/3 text-sm`}>
                                      {enquiry?.statuses.title}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <p className="text-slate-800 text-md">
                                  No client linked to this enquiry
                                </p>
                              )}
                            </div>

                            {showMatches ? (
                              <div className="p-4 pl-0 space-y-4">
                                <div className="pl-4 mb-8">
                                  <hr className="bg-gray-300 w-full my-4" />
                                  <h3 className="text-md font-bold mb-2">Possible Matches</h3>
                                  <p>
                                    These are possible matches based on the information
                                    provided above, please double check the information before
                                    progressing.
                                  </p>
                                </div>

                                {(localMatches?.length > 0 || kisMatches?.length > 0) && (
                                  <>
                                    <h3 className="text-md font-bold mb-2 pl-4">Local Matches</h3>
                                    {localMatches?.length > 0 ? (
                                      <>
                                        {localMatches.map((client) => {
                                          return (
                                            <PossibleMatches
                                              key={client.id}
                                              client={client}
                                              isKis={false}
                                              setSelected={selectClient}
                                              selectedKisClient={selectedClient}
                                              selectable={true}
                                            />
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <span className="pl-4">No matches were found locally</span>
                                    )}

                                    {kisMatches?.length > 0 ? (
                                      <>
                                        <h3 className={`text-md font-bold mb-2 pl-4 ${localMatches?.length > 0 ? "mt-8" : ''}`}>KIS Matches</h3>
                                        {kisMatches.map((client) => {
                                          return (
                                            <PossibleMatches
                                              key={client.id}
                                              client={client}
                                              isKis={false}
                                              setSelected={selectClient}
                                              selectedKisClient={selectedClient}
                                              highlightBypass={true}
                                              selectable={true}
                                            />
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <span className="pl-4">No matches were found for KIS</span>
                                    )}

                                  </>
                                )}

                              </div>
                            ) : (
                              <>
                                {checkedForMatches ? (
                                  <div className="w-full mt-8 mb-8">
                                    <span className="pl-4">No matches were found</span>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </>
                            )}

                            <div className="my-3 flex justify-end">
                              {enquiryClient == null && (
                                <>
                                  {linkClient ? (
                                    <button className={"btn primary"} onClick={getClientMatches}>
                                      Link Client
                                    </button>) : <span></span>}
                                  {localMatches.length < 1 && (
                                    <button
                                      className={"btn ml-4 primary"}
                                      onClick={sendCreateClientData}
                                    >
                                      Create Client
                                    </button>
                                  )}

                                </>
                              )}
                              {enquiryData.client != null && (
                                <button
                                  className={"btn ml-4 primary"}
                                  onClick={viewClient}
                                >
                                  View Client
                                </button>
                              )}
                              {selectedClient !== null && (
                                <button className={"btn ml-4 primary"} onClick={saveClient}>
                                  Save
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ViewEnquirySlideOut
