import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
//partials
// import Counselling from '../../partials/services/Counselling';
// import GPService from '../../partials/services/GPService';
// import Mindfulness from '../../partials/services/Mindfulness';
// import NurseSupport from '../../partials/services/NurseSupport';
// import Physiotherapy from '../../partials/services/Physiotherapy';
// import WeightManagement from '../../partials/services/WeightManagement';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons'
import MainTitle from '../../../components/MainTitle'

const CreateClaim = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [step, setStep] = useState(1);
  const { auth, setShowLoader, can } = useAuth()
  const axios = useAxiosPrivate()

  const [selectedService, setSelectedService] = useState({});
  const [statuses, setStatuses] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [claim, setClaim] = useState({});
  const [claimNotes, setClaimNotes] = useState('')
  const [contactTypes, setContactTypes] = useState([]);
  const [extraSessions, setExtraSessions] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [costAmount, setCostAmount] = useState(0);
  const [contactType, setContactType] = useState(1);
  const [services, setServices] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [clientData, setClientData] = useState([]);

  const handleBackToHome = () => {
    navigate(`/`)
  };

  const getContactTypes = async () => {
    try {
      await axios.get('/contact-types/all')
        .then(response => {
          setContactTypes(response.data.result);
        })

    } catch (error) {
      console.error(error)
    }
  }

  const getClientById = async () => {
    try {
      const response = await axios.get(`/clients/${id}`)
      await setClientData(response.data.result)

    } catch (error) {
      console.error(error)
    }
  }

  const toggleServices = (data) => {
    setServices(JSON.parse(data).services);
    setSelectedGroup(JSON.stringify(data));
  }

  const toggleSelectedService = (data) => {
    setSelectedService(JSON.parse(data))
    setCostAmount(data.default_unit_cost_amount)
    setCreditAmount(data.default_unit_credit_amount)
  }

  const getGroups = async () => {
    try {
      await axios.get('/service-groups/all')
        .then(response => {
          setGroups(response.data.result);
          setShowLoader(false)
        })

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setShowLoader(true)
    getClientById()
    getContactTypes();
    getGroups();
  }, [id])



  const handleSubmit = async (e) => {
    e.preventDefault();

    let costReserve = (selectedService.default_session_allocation * selectedService.default_unit_cost_amount)
    const formData = {
      claimable_id: id,
      claimable_type: "Client",
      service_id: selectedService.id,
      contact_type_id: contactType,
      claim_status_id: 1,
      description: claimNotes,
      extra_sessions: extraSessions,
      unit_credit_amount: selectedService.default_unit_credit_amount,
      unit_cost_amount: selectedService.default_unit_cost_amount,
      session_allocation: selectedService.default_session_allocation,
      cost_reserve: costReserve
    };
    try {
      const response = await axios.post(`claims/create`, formData);
      if (response.data.result) {
        navigate(`/clients/${id}/claims/${response.data.result}`);
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  const toolTipData = [
    {
      title: 'Name',
      value: clientData.name,
    },
    {
      title: 'Date of birth',
      value: clientData?.client_details?.date_of_birth,
    },
    {
      title: 'Email',
      value: clientData.email,
    }
  ]

  return (
    <section className='mx-auto mt-10'>
      <MainTitle title={`Client: ${clientData?.name}`} icon={true} toolTipData={toolTipData} backButton />
      <div className='mt-8'>
      </div>
      <div className='flex justify-around space-x-4 max-w-7xl mx-auto'>
        <div className='mb-8 w-full'>
          <form className="" onSubmit={handleSubmit}>
            <div className='bg-white rounded-lg shadow-lg p-4 w-full'>
              <div className='grid grid-cols-1 gap-4'>
                <div>
                  <label htmlFor="" className='text-slate-800'>Communication type</label>
                  <select onChange={(e) => setContactType(e.target.value)} defaultValue="0" className='"bg-gray-50 border border-gray-300 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500'>
                    <option value="0" disabled>
                      Select a type
                    </option>
                    {contactTypes.map((type) => {
                      return (
                        <option value={type.id}>{type.title}</option>
                      )
                    })}
                  </select>
                </div>
                <div>
                  <label className='text-slate-800' htmlFor="">Claim Notes</label>
                  <textarea
                    onChange={(e) => setClaimNotes(e.target.value)}
                    className="px-4 py-2 block w-full border border-gray-300 text-md bg-gray-50 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500"
                  />
                </div>
                <div>
                  <label htmlFor="" className='text-slate-800'>Choose type of service</label>
                  <select onChange={(e) => toggleServices(e.target.value)} defaultValue="0" className='bg-gray-50 border border-gray-300 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500'>
                    <option value="0" disabled>
                      Select a service
                    </option>
                    {groups?.map((group) => {
                      return (
                        <option value={JSON.stringify(group)}>{group.title}</option>
                      )
                    })}
                  </select>
                </div>
                {selectedGroup?.length > 0 && (
                  <div>
                    <label htmlFor="" className='text-slate-800'>Choose Supplier</label>
                    <select onChange={(e) => toggleSelectedService(e.target.value)} defaultValue="0" className='"bg-gray-50 border border-gray-300 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500'>
                      <option value="0" disabled>
                        Select a supplier
                      </option>
                      {services?.map((service) => {
                        return (
                          <option value={JSON.stringify(service)}>{service.provider.name}</option>
                        )
                      })}
                    </select>
                  </div>
                )}

                {selectedService.id && (
                  <div className='grid grid-cols-2 gap-4'>
                    <div>
                      <label htmlFor="" className='text-slate-800'>No. Sessions</label>
                      <input
                        type="number"
                        id="name"
                        required
                        disabled
                        value={selectedService.default_session_allocation}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="" className='text-slate-800'>Cost per session</label>
                      <input
                        type="number"
                        id="name"
                        required
                        disabled
                        value={selectedService.default_unit_cost_amount}
                        onChange={(e) => setCostAmount(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                      />
                    </div>
                    {can('add extra sessions') && (<div>
                      <label htmlFor="" className='text-slate-800'>Extra Sessions</label>
                      <input
                        type="number"
                        id="name"
                        defaultValue={extraSessions}
                        onChange={(e) => { setExtraSessions(e.target.value) }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                      />
                    </div>)}
                    {clientData?.policy?.product === 'SWB Bespoke (Credits)' && (<div>
                      <label htmlFor="" className='text-slate-800'>Credit cost</label>
                      <input
                        type="number"
                        id="name"
                        defaultValue={creditAmount}
                        onChange={(e) => { setCreditAmount(e.target.value) }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                      />
                    </div>)}
                  </div>

                )}
              </div>
              <div className='mt-8'>
                <button
                  type="submit"
                  className={`bg-violet-400 px-4 py-2 w-full rounded-lg shadow-md text-white font-medium hover:bg-violet-700`}
                >
                  Create Claim
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="mb-8 w-full">
          <div className="">
            <div className="bg-white rounded-lg shadow-lg p-4">
              <h2 className="text-slate-800 font-bold mb-4">
                Client Details
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="" className="text-slate-800 font-semibold">
                    Name
                  </label>
                  <p className="text-md text-violet-800">
                    {clientData?.name}
                  </p>
                </div>
                <div>
                  <label htmlFor="" className="text-slate-800 font-semibold">
                    Organisation
                  </label>
                  <p className="text-md text-violet-800">
                    {clientData?.organisation?.name}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </section>
  )
}

export default CreateClaim
