/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MainTitle from '../../../components/MainTitle'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom'
import Modal from '../../../components/Modal'
import { ImageConfig } from '../../../components/ImageConfig'
import {
  faCircleArrowDown,
  faCircleXmark,
  faDownload,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
// import './drop-file-input.css'

const Documents = () => {
  //Auth
  const { id } = useParams()
  const { setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const location = useLocation()
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })

  const getSharedDocuments = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(`/clients/${id}/documents`)
    
      if (response.data.result) {
        setShowFiles(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getCommAttachments = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(`/clients/${id}/comm-attachments`)
      if (response.data.result) {
        setCommAttachments(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    setClientData(location.state)
    getSharedDocuments()
    getCommAttachments()
  }, [])

  //drag and drop
  const wrapperRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [showFiles, setShowFiles] = useState([])
  const [commAttachments, setCommAttachments] = useState([])
  const [claimDocuments, setClaimDocuments] = useState([])
  const [selectedFile, setSelectedFile] = useState()
  const [errorMsg, setErrorMsg] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const onDragEnter = () => wrapperRef.current.classList.add('dragover')
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover')
  const onDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const onDrop = (e) => {
    e.preventDefault()
    wrapperRef.current.classList.remove('dragover')
    onFileDrop(e.nativeEvent.dataTransfer.files)
  }

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const allowedFileTypes = [
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    '.csv',
    'application/pdf',
    '.pdf',
    'image/jpeg',
    '.jpg',
    'image/png',
    '.png',
    'image/tiff',
    '.tiff',
  ];

  const isValidFileType = (file) => {
    const extension = `.${file.name.split('.').pop()}`;
    const mimeType = file.type;
    return (
      allowedFileTypes.includes(extension) ||
      allowedFileTypes.includes(mimeType)
    );
  };

  const onFileUpload = async (e) => {
    e.preventDefault();
    const newFile = e.target.files[0];

    if (newFile && isValidFileType(newFile)) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
    } else {
      console.error('Invalid file type. Please select a valid file.');
    }
  };

  const onFileDrop = async (files) => {
    const newFile = files[0];
  
    if (newFile && isValidFileType(newFile)) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
    } else {
      createInfo('error', 'Invalid file type');
      return
    }
  };

  const validateSelectedFile = async () => {
    const file_size = await axios.get(`/clients/max-file-size`)

    const MAX_FILE_SIZE = file_size.value // 10MB

    if (!selectedFile) {
      setErrorMsg('Please choose a file')
      setIsSuccess(false)
      return
    }

    const fileSizeKiloBytes = selectedFile.size / 1024

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg('File size is greater than maximum limit')
      setIsSuccess(false)
      return
    }

    setErrorMsg('')
    setIsSuccess(true)
  }

  const uploadDocuments = async () => {
    validateSelectedFile()

    setShowLoader(true)
    const fd = new FormData()
    for (let i = 0; i < fileList.length; i++) {
      fd.append('shared-document-' + i, fileList[i], fileList[i].name)
    }
    try {
      const response = await axios.post(`/clients/${id}/upload-documents`, fd)

      if (response.data.result) {
        setShowFiles([...showFiles, ...response.data.result])
      }

      setFileList([])

      createInfo(
        'success',
        'Uploaded ' + response.data.result.length + ' document(s)'
      )
    } catch (error) {}
    setShowLoader(false)
  }

  const fileRemove = (file) => {
    const updatedList = [...fileList]
    updatedList.splice(fileList.indexOf(file), 1)
    setFileList(updatedList)
  }

  const deleteFile = async (file) => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/clients/${id}/delete-document/${file.id}`
      )

      if (!response.data.error) {
        const updatedList = [...showFiles]
        updatedList.splice(showFiles.indexOf(file), 1)
        setShowFiles(updatedList)

        createInfo('success', 'Document removed')
      } else {
        createInfo('error', 'Could not remove document')
      }
    } catch (error) {
      createInfo('error', 'Could not remove document')
    }
    setShowLoader(false)
  }

  const deleteAttachment = async (file) => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/clients/${id}/delete-attachment/${file.id}`
      )

      if (!response.data.error) {
        const updatedList = [...commAttachments]
        updatedList.splice(commAttachments.indexOf(file), 1)
        setCommAttachments(updatedList)

        createInfo('success', 'Document removed')
      } else {
        createInfo('error', 'Could not remove document')
      }
    } catch (error) {
      createInfo('error', 'Could not remove document')
    }
    setShowLoader(false)
  }

  const ModalBody = () => {
    if (assignRisk) {
      return <CreateRiskAssignElement />
    }
  }

  const resetModal = () => {
    setShowModal(false)
  }

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <h2 className="font-bold text-lg mb-4">Shared Documents</h2>

        <div className="grid grid-cols-4 gap-3">
          {showFiles?.map((item, index) => {
            return (
              <div
                key={index}
                className="grid lg:grid-cols-3 gap-2"
              >
                <div className="flex flex-wrap items-center col-span-2">
                  <img
                    src={
                      ImageConfig[item.name.split('.')[1]] ||
                      ImageConfig['default']
                    }
                    alt=""
                    className="w-14 xl:w-16"
                  />

                  <div className="drop-file-preview__item__info text-sm xl:text-base break-all">
                    <p>{item.name}</p>
                  </div>
                </div>

                <div className="flex items-center justify-end space-x-2">
                  <div
                    className=" hover:text-red-700 cursor-pointer "
                    onClick={() => deleteFile(item)}
                  >
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className="w-6 h-6 xl:w-8 xl:h-8 text-gray-700 hover:text-red-700"
                    />
                  </div>

                  <a href={item.url} target="_blank">
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="w-3 h-3 xl:w-4 xl:h-4 border border-green-200 text-emerald-700 cursor-pointer rounded-full bg-green-200 p-1.5 xl:p-2 hover:bg-transparent hover:border-emerald-700"/>
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <h2 className="font-bold text-lg mb-4">Comm Attachments</h2>

        <div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
            {commAttachments?.map((item, index) => {
              return (
                <div  key={index}>
                  <div>
                    <span className='font-medium'> Thread:</span> {item?.threads[0]}
                  </div>
                <div className="grid lg:grid-cols-4 gap-2" >
                  <div className="flex flex-wrap items-center col-span-3">
                    <img
                      src={
                        ImageConfig[item.name.split('.')[1]] ||
                        ImageConfig['default']
                      }
                      alt=""
                      className="w-14 xl:w-16"
                    />

                    <div className="drop-file-preview__item__info text-sm break-all">
                      <p>{item.name}</p>
                    </div>
                  </div>

                  <div className="flex items-center justify-end space-x-2">
                    <div
                      className=" hover:text-red-700 cursor-pointer "
                      onClick={() => deleteAttachment(item)}
                    >
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="w-6 h-6 xl:w-8 xl:h-8 text-gray-700 hover:text-red-700"
                      />
                    </div>

                    <a href={item.url} target="_blank">
                      <FontAwesomeIcon
                        icon={faDownload}
                        className="w-3 h-3 xl:w-4 xl:h-4 border border-green-200 text-emerald-700 cursor-pointer rounded-full bg-green-200 p-1.5 xl:p-2 hover:bg-transparent hover:border-emerald-700"
                      />
                    </a>
                  </div>
                </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <div className="flex items-center justify-between mb-4">
          <h2 className="font-bold text-lg">
            Upload Documents
          </h2>
          <button
            onClick={uploadDocuments}
            className="rounded-lg border border-teal-500 bg-teal-500 text-white px-4 py-2 flex items-center hover:bg-transparent hover:text-teal-500"
          >
            <FontAwesomeIcon icon={faUpload} />
            <span className="pl-2"> Save</span>
          </button>
        </div>
        <label
          htmlFor="file-upload"
          className="col-span-full"
          ref={wrapperRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium leading-6 text-gray-900"
          ></label>
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div className="text-center">
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="mx-auto h-12 w-12 text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                <div className="relative cursor-pointer rounded-md bg-white font-semibold text-dark-purple focus-within:outline-none focus-within:ring-2 focus-within:ring-nav focus-within:ring-offset-2 hover:text-dark-purple">
                  <span>Drag and Drop or click to upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,text/csv,.pdf,application/pdf,image/jpeg,image/png,image/tiff"
                    multiple
                    className="sr-only"
                    onChange={onFileUpload}
                  />
                </div>
                {/* <p className="pl-1">or drag and drop</p> */}
              </div>
              <p className="text-xs leading-5 text-gray-600">
                PNG, JPG, CSV, XLS or PDF up to 10MB
              </p>
              <p className="error-message">{errorMsg}</p>
            </div>
          </div>
        </label>
        <div className="grid grid-cols-4 gap-3 mt-8">
          {fileList?.map((item, index) => (
            <div
              key={index}
              className="drop-file-preview__item flex items-center justify-between "
            >
              <div className="flex items-center">
                <img
                  src={
                    ImageConfig[item.type.split('/')[1]] ||
                    ImageConfig['default']
                  }
                  alt=""
                  className="w-16"
                />

                <div className="drop-file-preview__item__info">
                  <p className="break-words">{item.name}</p>
                  <p>{item.size}</p>
                </div>
              </div>

              <span
                className=" hover:text-red-700 drop-file-preview__item__del"
                onClick={() => fileRemove(item)}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className=" text-gray-700 hover:text-red-700 w-8 h-8"
                />
              </span>
            </div>
          ))}
        </div>
      </section>
    </section>
  )
}

export default Documents
