// import { faTimes } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Modal({ title, body, show, width ='550px' }) {
  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center"
      style={{ zIndex: 999 }}
    >
      <div className="bg-slate-700/50 w-full h-full">&nbsp;</div>
        <div
          className="flex flex-col absolute p-6 bg-white rounded-xl shadow-md"
          style={{ width: width }} 
        >
        <h2 className="text-left text-hub-primary text-2xl font-bold border-b border-b-slate-300 pb-2">
          {title}
        </h2>
        <div className="mt-4">{body}</div>
      </div>
    </div>
  )
}

export default Modal
