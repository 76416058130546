import MainTitle from '../../components/MainTitle'
import { useState, useEffect, Link, Fragment } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import Pagination from '../../components/Pagination';
import useAuth from '../../hooks/useAuth';
import { useParams, useLocation, useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faEye, faSortUp, faSort, faSortDown, faCircleXmark, faUserPlus, faLink, faChevronDown, faMailBulk, faEnvelope, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from '../../components/Modal'
import { Menu, Transition } from '@headlessui/react'
import ViewEnquiry from '../../components/ViewEnquirySlideOut'

const Enquiries = () => {
  const { id } = useParams()
  const { setShowLoader, can, createInfo, auth, admin } = useAuth()
  const axios = useAxiosPrivate()
  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentRecords, setCurrentRecords] = useState([])
  const navigate = useNavigate();

  const [search, setSearch] = useState('')

  // No of Records to be displayed on each page
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});

  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [closeEnquiry, setCloseEnquiry] = useState(false)
  const [deleteEnquiry, setDeleteEnquiry] = useState(false)
  const [clients, setClients] = useState({})
  const [linkClient, setLinkClient] = useState({})
  const [linkClientModal, setLinkClientModal] = useState(false)
  const [enquiries, setEnquiries] = useState([])
  const [reasons, setReasons] = useState([])
  const [statuses, setStatuses] = useState([])
  const [cancelReasons, setCancelReasons] = useState({})
  const [enquiry, setEnquiry] = useState(null)
  const [open, setOpen] = useState(false)
  const [stats, setStats] = useState([])
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const [assignUser, setAssignUser] = useState(false)
  const [users, setUsers] = useState([]);
  const [userAssignedId, setUserAssignedId] = useState('');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const viewEnquiryClicked = (enquiry) => {
    // navigate(`/enquiries/${id}`)
    setOpen(true)
    setEnquiry(enquiry)
  }

  const [statusFilter, setStatusFilter] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');

  const getEnquiries = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/enquiries`)
      if (response.data.result) {
        await setEnquiries(response.data.result)
        setOriginalRecords(response.data.result)
        await setCurrentRecords(response.data.result)
        await setFilteredRecords(response.data.result)
        await calculateStats(response.data.result);
        setShowLoader(false)
      }

      setNumberOfPages(Math.ceil(response.data.result.length / recordsPerPage))
    } catch (error) {
      console.error(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  useEffect(() => {
    let filtered = enquiries

    if (statusFilter) {
      filtered = filtered.filter(enquiry =>
        enquiry.statuses?.title?.toLowerCase() === statusFilter.toLowerCase()
      )
    }

    if (search) {
      const searchString = search.trim().toLowerCase()
      filtered = filtered.filter(enquiry =>
        enquiry.name.toLowerCase().includes(searchString)
      )
    }

    setFilteredRecords(filtered)
    setCurrentRecords(filtered.slice(indexOfFirstRecord, indexOfLastRecord))
    setCurrentPage(1)
  }, [search, statusFilter, enquiries])

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value)
    setDropdownValue(e.target.value)
  };

  const resetFilters = () => {
    setStatusFilter('');
    setSearch('');
    setDropdownValue('');
  };

  const isFilterApplied = () => {
    return statusFilter !== '' || search !== '';
  };

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = enquiries.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(enquiries)
      setCurrentRecords(enquiries.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(enquiries.length / recordsPerPage))
    }
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });

      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const assignUserClicked = (enquiry) => {
    setUserAssignedId(enquiry.assigned_to_id ?? "")
    setAssignUser(true);
    setEnquiry(enquiry);
    setModalTitle(`Assign User to Enquiry`);
    setShowModal(true);
  };

  const sortArray = (array, key, direction) => {

    if (!key) {
      return [...array].sort((a, b) => {
        if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }

    return [...array].sort((a, b) => {

      const segments = key.split('.');
      let aKey = a;
      let bKey = b;

      segments.forEach(element => {
        if (!aKey[element]) {
          aKey = '';
          return;
        }

        if (!bKey[element]) {
          bKey = '';
          return;
        }

        aKey = aKey[element];
        bKey = bKey[element];
      });

      if (aKey < bKey) return direction === 'ascending' ? -1 : 1;
      if (aKey > bKey) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);

      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  useEffect(() => {
    getEnquiries();
  }, [statusFilter]);

  const getCompleteEnquiryReasons = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/enquiries/enquiry-reasons`)
      if (response.data) {
        await setReasons(response.data.result)
        setShowLoader(false)
      }
    } catch (error) {
      console.error(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const getClients = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/all`)
      if (response.data) {
        await setClients(response.data.result)
        setShowLoader(false)
      }
    } catch (error) {
      console.error(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const getStatuses = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/enquiries/statuses`)
      if (response.data) {
        await setStatuses(response.data.result)
        setShowLoader(false)
      }
    } catch (error) {
      console.error(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setShowLoader(true);
        const usersResponse = await axios.get('/users/all');
        if (usersResponse.data.result) {
          setUsers(usersResponse.data.result);
        }

      } catch (error) {
        console.error(error);
      } finally {
        setShowLoader(false);
      }
      setShowLoader(false)
    };

    fetchUsers();
    getStatuses()
    getEnquiries()
    getClients()
    getCompleteEnquiryReasons()
  }, [])

  const closeEnquiryClicked = (enquiry) => {
    setCloseEnquiry(true);
    setEnquiry(enquiry);
    setModalTitle(`Close Enquiry`);
    setShowModal(true);
  };

  const linkClientClicked = (enquiry) => {
    setLinkClientModal(true);
    setEnquiry(enquiry);
    setLinkClient(enquiry.client_id)
    setModalTitle(`Link Client`);
    setShowModal(true);
  };

  const deleteEnquiryClicked = (enquiry) => {
    setDeleteEnquiry(true);
    setEnquiry(enquiry);
    setModalTitle(`Delete Enquiry`);
    setShowModal(true);
  };

  const ModalBody = () => {
    if (assignUser) {
      return <AssignUserElement />
    }

    if (linkClientModal) {
      return <LinkClientElement />
    }

    if (closeEnquiry) {
      return <CloseEnquiryElement />
    }

    if (deleteEnquiry) {
      return <DeleteEnquiryElement />
    }

  }

  const CloseEnquiryElement = () => {
    const completeEnquiry = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/enquiries/close-enquiry`, {
          cancel_reason: JSON.parse(cancelReasons).id,
          id: enquiry.id,
        });
        getEnquiries();
      } catch (error) {
        if (error === 'SyntaxError: "[object Object]" is not valid JSON') {
          createInfo('error', `Please fill out the required fields`);
        } else {
          createInfo('error', `Failed to create claim`);
        }
      }
      resetModal();
      setShowLoader(false);
    };

    return (
      <form onSubmit={completeEnquiry}>
        <div className="mb-3">
          Are you sure you want to close <span className='font-medium'>{enquiry.name}</span>?
        </div>
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Please give a reason <span className="text-red-500">*</span>
          </label>
          <select
            defaultValue={cancelReasons}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md capitalize"
            required
            id="external_source"
            onChange={(e) => setCancelReasons(e.target.value)}
          >
            <option disabled value={{}}>
              Select Completed Reason
            </option>
            {reasons?.map((reason) => {
              return (
                <option key={reason.id} value={JSON.stringify(reason)}>
                  {reason.title}
                </option>
              )
            })}
          </select>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Complete Enquiry</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}>
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const AssignUserElement = (e) => {
    const completeEnquiry = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const userAssigned = users.find(user => user.id.toString() === userAssignedId);
        if (!userAssigned) throw new Error('User not found');
        const response = await axios.post(`/enquiries/update-user/${enquiry.id}`, {
          assigned_to_id: userAssigned.id,
          enquiry_id: enquiry.id,
        });
        createInfo('success', `Enquiry assigned successfully`);
        getEnquiries();
      } catch (error) {
        console.error(error);
        createInfo('error', error.response?.data?.message || `Failed to assign enquiry`);
      } finally {
        resetModal();
        setShowLoader(false);
      }
    };

    return (
      <form onSubmit={completeEnquiry}>
        <div className="mb-3">
          Please choose a user to assign to <span className='font-medium'>{enquiry.name}</span> from the list below:
        </div>
        <div className="mb-3">
          <label htmlFor="userSelect" className="block font-medium text-gray-600 text-sm">
            Users <span className="text-red-500">*</span>
          </label>
          <select
            defaultValue={userAssignedId}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md capitalize"
            required
            id="userSelect"
            onChange={(e) => setUserAssignedId(e.target.value)}
          >
            <option disabled value="">
              Select a user
            </option>
            {users?.map((user) => (
              <>
                <option key={user.id} value={user.id}>
                  {user?.name}
                </option>
              </>
            ))}
          </select>
        </div>
        <div className="flex mt-2 w-full">
          <button type="submit" className="btn mt-4 mr-4 w-1/2">Assign</button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>Cancel</button>
        </div>
      </form>
    );
  };

  const LinkClientElement = () => {
    const createLink = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/enquiries/link-client`, {
          client_id: linkClient,
          id: enquiry.id,
        });
        getEnquiries();
      } catch (error) {
        if (error === 'SyntaxError: "[object Object]" is not valid JSON') {
          createInfo('error', `Please fill out the required fields`);
        } else {
          createInfo('error', `Failed to create claim`);
        }
      }
      resetModal();
      setShowLoader(false);
    };

    return (
      <form onSubmit={createLink}>
        <div className="mb-3">
          You are linking a client to <span className='font-medium'>{enquiry.name}</span>.
        </div>
        <div className="mb-3">
          <label
            htmlFor="link_client"
            className="block font-medium text-gray-600 text-sm"
          >
            Clients <span className="text-red-500">*</span>
          </label>
          <select
            defaultValue={linkClient}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md capitalize"
            required
            id="link_client"
            onChange={(e) => setLinkClient(e.target.value)}
          >
            <option disabled value={{}}>
              Select Client
            </option>
            {clients?.map((client) => {
              return (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              )
            })}
          </select>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update Client</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}>
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const DeleteEnquiryElement = () => {
    const destroyEnquiry = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/enquiries/delete/${enquiry.id}`);
        getEnquiries();
      } catch (error) {
        if (error === 'SyntaxError: "[object Object]" is not valid JSON') {
          createInfo('error', `Please fill out the required fields`);
        } else {
          createInfo('error', `Failed to create claim`);
        }
      }
      resetModal();
      setShowLoader(false);
    };

    return (
      <form onSubmit={destroyEnquiry}>
        <div className="mb-3">
          Are you sure you want to delete <span className='font-medium'>{enquiry.name}</span>?
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Delete Enquiry</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setShowModal(false)

    setCloseEnquiry(false)
    setLinkClient(false)
    setLinkClientModal(false)
    setDeleteEnquiry(false)
    setAssignUser(false)
    setModalTitle('')
  }

  const calculateStats = (allEnquiries) => {
    const totalEnquiries = allEnquiries.length

    const currentWeekStart = moment().startOf('isoWeek')
    const lastWeekStart = moment().subtract(1, 'weeks').startOf('isoWeek')
    const lastWeekEnd = moment().subtract(1, 'weeks').endOf('isoWeek')
    const enquiriesThisWeek = allEnquiries.filter(enquiry =>
      moment(enquiry.created_at).isSameOrAfter(currentWeekStart)
    ).length

    const enquiriesLastWeek = allEnquiries.filter(enquiry =>
      moment(enquiry.created_at).isBetween(lastWeekStart, lastWeekEnd)
    ).length

    let change = 0;
    let changeType = 'increase'

    if (enquiriesLastWeek > 0) {
      change = ((enquiriesThisWeek - enquiriesLastWeek) / enquiriesLastWeek) * 100;
      changeType = enquiriesThisWeek >= enquiriesLastWeek ? 'increase' : 'decrease';
    } else if (enquiriesThisWeek > 0) {
      change = 100
      changeType = 'increase'
    } else {
      change = 0
    }

    setStats([
      { id: 1, name: 'Total Enquiries', stat: totalEnquiries, icon: faMailBulk },
      { id: 2, name: 'Enquiries This Week', stat: enquiriesThisWeek, icon: faEnvelope, change: `${Math.abs(change.toFixed(1))}%`, changeType }
    ]);
  }

  return (
    <section>
      <MainTitle title="Enquiries" />
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <ViewEnquiry open={open} setOpen={setOpen} enquiry={enquiry} />
      <section className="mt-6">
        <div className='mb-4'>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.id}
                className=" overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:pt-6"
              >
                <dt>
                  <div className="absolute rounded-md bg-dark-purple px-3 py-2">
                    <FontAwesomeIcon icon={item.icon} className="h-6 w-6 text-white" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                  {item.change && (
                    <p
                      className={`ml-2 flex items-baseline text-sm font-semibold ${item.changeType === 'increase' ? 'text-green-600' : 'text-red-600'}`}
                    >
                      {item.changeType === 'increase' ? (
                        <FontAwesomeIcon icon={faArrowUp} aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-green-500" />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-red-500" />
                      )}
                      <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                      {item.change}
                    </p>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className='relative'>
          <div className="flex items-center w-full justify-between"></div>
          <div className="pb-4 flex items-center">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  onClick={handleSearch}
                />
              </div>
              <input
                type="text"
                id="table-search"
                onChange={handleSearch}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-80 pl-10 p-2.5  "
                placeholder="Search Enquiries"
              />
            </div>
            {can('view client status') &&
              <div className="ml-auto relative">
                <select
                  onChange={handleStatusChange}
                  value={dropdownValue}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-md mr-2"
                >
                  <option value="">Filter by Status</option>
                  {statuses.map((status, index) => {
                    return <option key={index} value={status.title.toLowerCase()}>
                      {status.title}
                    </option>
                  })}
                </select>
                {isFilterApplied() && (
                  <button
                    onClick={resetFilters}
                    className="bg-red-500 text-white rounded-lg px-4 py-2 mr-2"
                  >
                    Reset Filters
                  </button>
                )}
              </div>
            }
          </div>

          <table className="overflow-auto table-main mt-4 mb-10">
            <thead>
              <tr>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('name')}>Name {renderSortArrow('name')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('service.name')}>Service Requested {renderSortArrow('service.name')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('reference')}>Reference {renderSortArrow('reference')}</th>
                {can('view client status') && <th className='cursor-pointer' scope="col" onClick={() => handleSort('statuses.title')}>Status {renderSortArrow('statuses.title')} </th>}
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('user.name')}>Assigned To {renderSortArrow('user.name')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('created_at')}>Created At {renderSortArrow('created_at')}</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 ? (
                currentRecords?.map((enquiry) => {
                  return (
                    <tr key={enquiry.id}>
                      <td>{enquiry.name}</td>
                      <td>{JSON.parse(enquiry.info).service?.name}</td>
                      <td>{enquiry.reference}</td>
                      <td>
                        {enquiry.enquiryReasons !== null ? (
                          <div className={`bg-rose-200 text-rose-800 rounded-full py-1 px-2 capitalize`}>{enquiry.enquiryReasons}</div>
                        ) : (
                          <div className={`bg-${enquiry.statuses.colour}-200 text-${enquiry.statuses.colour}${enquiry.statuses.colour === 'orange' ? '-800' : '-700'} rounded-full py-1 px-2 capitalize`}>{enquiry.statuses.title}</div>
                        )}
                      </td>
                      <td>{enquiry.user?.name}</td>
                      <td>{moment(enquiry.created_at).format('DD/MM/YYYY')}</td>
                      <td>
                        {
                          (enquiry.statuses?.title?.toLowerCase() !== 'escalated to nurses' ||
                            (enquiry.statuses?.title?.toLowerCase() === 'escalated to nurses' && can('access escalated to nurses enquiries')) ||
                            (enquiry.statuses?.title?.toLowerCase() === 'escalated to nurses' && can('update escalated to nurses enquiries'))) && (

                            <Menu as="div" className="inline-block text-left overflow-visible">
                              <div>
                                <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2 hover:bg-gray-300 px-2 py-1">
                                  <span className="font-semibold text-sm mr-2">
                                    Actions
                                  </span>
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <div>
                                  <Menu.Items className="absolute z-10 right-6 mt-2  divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="p-2 space-y-2">
                                      {(enquiry.statuses?.title?.toLowerCase() !== 'escalated to nurses' || (enquiry.statuses?.title?.toLowerCase() === 'escalated to nurses' && can('access escalated to nurses enquiries'))) &&
                                        <Menu.Item>
                                          <div onClick={() => viewEnquiryClicked(enquiry)} title="View Enquiry" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer">
                                            <FontAwesomeIcon icon={faEye} className='text-blue-800' /> <div className='pl-2'>View</div>
                                          </div>
                                        </Menu.Item>
                                      }
                                      {(enquiry.statuses?.title?.toLowerCase() !== 'escalated to nurses' || (enquiry.statuses?.title?.toLowerCase() === 'escalated to nurses' && can('update escalated to nurses enquiries'))) &&
                                        <>
                                          <Menu.Item>
                                            <div onClick={() => assignUserClicked(enquiry)} title="Assign User" className="flex justify-start items-center rounded-md px-2 py-1 hover:bg-pink-200 cursor-pointer">
                                              <FontAwesomeIcon icon={faUserPlus} className='text-pink-800' /> <div className='pl-2'>Assign User</div>
                                            </div>
                                          </Menu.Item>
                                          <>
                                            {enquiry.enquiryReasons === '' && (
                                              <Menu.Item >
                                                <div onClick={() => closeEnquiryClicked(enquiry)} title="Close Enquiry" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-amber-200 cursor-pointer">
                                                  <FontAwesomeIcon icon={faCircleXmark} className='text-amber-800' /> <div className='pl-2'>Close Enquiry</div>
                                                </div>
                                              </Menu.Item>
                                            )}
                                          </>
                                          <Menu.Item >
                                            <>
                                              {enquiry.clientName === '' && (
                                                <div onClick={() => linkClientClicked(enquiry)} title="Link Client" className="flex justify-start items-centerrounded-md  px-2 py-1 hover:bg-violet-200 cursor-pointer">
                                                  <FontAwesomeIcon icon={faLink} className='text-violet-800' /> <div className='pl-2'>Link Client</div>
                                                </div>
                                              )}
                                            </>
                                          </Menu.Item>
                                        </>
                                      }
                                    </div>
                                  </Menu.Items>
                                </div>
                              </Transition>
                            </Menu>
                          )
                        }
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <p className="no-records">No enquiries found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRecords.length}
          />
        </div>
      </section>
    </section>
  )
}

export default Enquiries
