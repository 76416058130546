import React from 'react';
import { AnalogClock } from '@hoseinh/react-analog-clock';
import moment from 'moment'

const Clock = () => {
  const options = {
    width: 20,
    size: "80px",
    border: true,
    borderColor: '#2e2e2e',
    baseColor: '#ffffff',
    centerColor: '#459cff',
    centerBorderColor: '#ffffff',
    handColors: {
      second: '#d81c7a',
      minute: '#000000',
      hour: '#000000'
    },
    handLength:'hour: "25px", minute: "50px", second: "50px"',
    numbersColor: '#000000',
    showNumbers: true,
    strokeWidth: 2,
    showSmallTicks: true,
    renderNumbers: true,
  };

  return (
    <div className="flex items-center">
      <AnalogClock
        showMinuteHand={true}
        showSecondHand={true}
        showBorder={true}
        showHandBase={true}
        smooth={false}
        whiteNumbers={false}
        square={false}
        numbersType="numbersAndLines"
        borderColor="#000000"
        handBaseColor="#000000"
        handColor={{ hour: "#000000", minute: "#000000", second: "#e74c3c" }}
        handLength={{ hour: "25px", minute: "35px", second: "35px" }}
        size={80}
        backgroundColor="#ffffff"
      />
      
      <div className="ml-4">
        <p className="text-lg font-semibold">{moment().format('DD/MM/YYYY')}</p>
        <p className="text-gray-600 text-base">{moment().format('HH:mm')}</p>
      </div>
    </div>
  );
};

export default Clock;