import {
  faTimes,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from '../hooks/useAuth'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useLogout from '../hooks/useLogout'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function Header({ collapsed }) {
  const { setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const navigate = useNavigate()

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [searchType, setSearchType] = useState('staff')
  const [mediaWidth, setMediaWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setMediaWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const headerStyles = {
    width: 'calc(100% - 16rem)',
  }

  const toggleSearchType = (type) => {
    setSearchType(type)
    setSearchQuery('');
    setSearchResults([])
  }

  const runSearch = async () => {
    try {
      let kisResults = [];
      let localResults = [];
      let combinedResults = [];
      let url = "";
      let singlurl = "";

      switch(searchType) {
        case "staff":
          url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/search/staff?api_token=${process.env.REACT_APP_API_KEY}`;
          singlurl = `/search/clients/${searchQuery}`;
          break;
        case "organisation":
          url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/org-search?api_token=${process.env.REACT_APP_API_KEY}`;
          singlurl = `/search/organisations/${searchQuery}`;
          break;
        default:
        case "referral":
          url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/search/referral?api_token=${process.env.REACT_APP_API_KEY}`;
      }

      if(url !== "") {
        const kisResponse = await axios.post(url, { q: searchQuery });
        kisResults = kisResponse?.data?.data || [];
      }

      if(singlurl !== "") {
        const localResponse = await axios.post(singlurl);
        localResults = localResponse?.data?.result?.original?.result || [];
        combinedResults = [...localResults, ...kisResults];
      }

      if (searchType === "staff") {

        const filteredResults = combinedResults.filter((item, index) =>
          index === combinedResults.findIndex((elem) =>
            elem.name === item.name && elem.date_of_birth === item.date_of_birth
          )
        );

        setSearchResults(filteredResults);
      } else if (searchType === "organisation") {

        const filteredResults = combinedResults.filter((item, index) =>
          index === combinedResults.findIndex((elem) =>
            elem.name === item.name &&
            elem.unique_reference === item.unique_reference
          )
        );

        setSearchResults(filteredResults);

        if (!filteredResults?.id) {
          console.error("Selected result does not have an ID");
          return;
        }

        const updateUrl = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/updateOrg/${filteredResults.id}?api_token=${process.env.REACT_APP_API_KEY}`;

        try {
          await axios.get(updateUrl);
        } catch (error) {
          console.error("Failed to update organisation:", error.message);
        }
      } else if (searchType === "referral") {
        setSearchResults(kisResults);
      }
    } catch (error) {
      console.error("Error during search:", error);
      setSearchResults([]);
    }
  };

  // const runSearch = async () => {
  //   let url = `
  //   let kisStaff = [];
  //   let localStaff = [];
  //   let results;
  //   if(searchType === 'staff')
  //   {
  //     url = ${BaseKisUrl}/api/v1/singl/search/staff?api_token=${process.env.REACT_APP_API_KEY}
  //     await axios.post(url, {q: searchQuery}).then((response) => {
  //     kisStaff = response.data.data;
  //     url = /search/clients/${searchQuery}
  //     axios.post(url).then((response) => {
  //       if(response.data.result.original.result.length > 0)
  //       {
  //         localStaff = response.data.result.original.result;
  //         localStaff.map((item) => {
  //           return kisStaff.unshift(item);
  //         })
  //         let filtered = kisStaff.filter( (ele, ind) => ind === kisStaff.findIndex( elem => elem.name === ele.name && elem.date_of_birth === ele.date_of_birth))
  //         setSearchResults(filtered)
  //       }else
  //       {
  //         setSearchResults(kisStaff);
  //       }
  //     })
  //     });
  //   }

  //   if(searchType === 'organisation')
  //   {
  //     let localOrgs = ''
  //     let kisOrgs = ''
  //     url = ${BaseKisUrl}/api/v1/singl/org-search?api_token=${process.env.REACT_APP_API_KEY}
  //     await axios.post(url, {q: searchQuery}).then((response) => {
  //     kisOrgs = response.data.data;
  //     url = /search/organisations/${searchQuery}
  //     axios.post(url).then((response) => {
  //       if(response.data.result.original.result.length > 0)
  //       {
  //         localOrgs = response.data.result.original.result;
  //         localOrgs.map((item) => {
  //           return kisOrgs.unshift(item);
  //         })
  //         let filtered = kisOrgs.filter( (ele, ind) => ind === kisOrgs.findIndex( elem => elem.name === ele.name && elem.unique_reference === ele.unique_reference))
  //         setSearchResults(filtered)
  //       }else
  //       {
  //         setSearchResults(kisStaff);
  //       }
  //     })
  //     })
  //   }
  // }


  useEffect(() => {
    setSearchResults([])
    if (searchQuery.length >= 3) {
      runSearch()
    }
  }, [searchQuery])

  const viewClient = async (data) => {
    try {
      setShowLoader(true);

      if (searchType === 'staff') {
        const response = await axios.post(`clients/exists`, { data: data })
        setShowLoader(false);
        navigate(`/clients/${response.data.result}`);
      }

      if (searchType === 'organisation') {
        const response = await axios.post(`organisations/exists`, { data: data }).then((response) => {
          setShowLoader(false);
          navigate(`/organisations/${response.data.result}`);
        })
      }
      if(searchType === 'referral') {
        if(data.wbs_case.case_type === 'Staff') {
          const selectedStaff = data.wbs_case.staffs;
          
          const response = await axios.post(`${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/get-org-from-referral/${data.claim_reference}?api_token=${process.env.REACT_APP_API_KEY}`).then(async (response) => {
            if (response.data) {
              let org = response.data.data.org
              let policies = response.data.data.policies
          
              let staffData = {
                'id': selectedStaff.staff_id,
                'name': selectedStaff.name,
                'date_of_birth': selectedStaff.date_of_birth,
                'email': selectedStaff.email,
                'address': selectedStaff.address,
                'school_name': org.name,
                'school_urn': org.urn,
                'policy_id': data.wbs_case.policy_id,
                'policys': policies,
              }
      
              const staff = await axios.post(`clients/exists`, { data: staffData }).then((staffResponse) => {
                setShowLoader(false);
                if(staffResponse.data.result) {
                  window.location.href = `/clients/${staffResponse.data.result}?claim_reference=${data.claim_reference}`;
                }
              });
            }
          });
        
        } else {
          const response = await axios.post(`${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/get-org-from-referral/${data.claim_reference}?api_token=${process.env.REACT_APP_API_KEY}`).then(async (response) => {
            if (response.data) {
              let org = response.data.data.org
              let contacts = response.data.data.contacts
              let policies = response.data.data.policies
    
              let orgData = {
                'name': org.name,
                'unique_reference': org.urn,
                'address': org.address,
                'contacts': contacts,
                'main_contact' : org.main_contact,
                'policys': policies,
                'type': org.academy_id ? 1 : 2
              }
              const organisation = await axios.post(`organisations/exists`, { data: orgData }).then((organisationResponse) => {
                setShowLoader(false);
                  window.location.href = `/organisations/${organisationResponse.data.result}?claim_reference=${data.claim_reference}`;
              })
            }
          });
        }
      }
      setSearchQuery('')
      setSearchResults([])
      setSearchType('staff')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <header
      className="w-full px-4 py-3 flex justify-between items-center"
      style={headerStyles}
    >
      <div className="flex items-center relative">
        <div className="flex items-center space-x-2">
          <div
            className={`flex items-center border rounded-full px-4 bg-white transition-all duration-300 w-64 opacity-100`}
          >
            <input
              type="text"
              id="main_search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full h-9 pl-2 text-sm text-slate-800 focus:outline-none"
              placeholder="Search"
            />
            <span
              className="cursor-pointer ml-2"
              onClick={() => {
                setSearchQuery("");
                setSearchResults([]);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>

          <span
            className={`flex justify-center items-center rounded-md h-9 w-20 ml-2 cursor-pointer transition ${searchType === "staff"
                ? "bg-violet-400 text-white hover:bg-violet-300"
                : "bg-slate-100 text-slate-500 hover:bg-slate-200"
              }`}
            onClick={() => toggleSearchType("staff")}
          >
            Clients
          </span>
          <span
            className={`flex justify-center items-center rounded-md h-9 w-32 mx-2 cursor-pointer transition ${searchType === "organisation"
                ? "bg-violet-400 text-white hover:bg-violet-300"
                : "bg-slate-100 text-slate-500 hover:bg-slate-200"
              }`}
            onClick={() => toggleSearchType("organisation")}
          >
            Organisation
          </span>
          <span
            className={`flex justify-center items-center rounded-md h-9 w-20 mx-2 cursor-pointer transition ${searchType === "referral"
                ? "bg-violet-400 text-white hover:bg-violet-300"
                : "bg-slate-100 text-slate-500 hover:bg-slate-200"
              }`}
            onClick={() => toggleSearchType("referral")}
          >
            Referral
          </span>
        </div>
        
        {searchResults && searchResults.length > 0 ? (
          <div
            className="absolute -left-4 bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px] z-10 overflow-y-scroll"
            style={{ top: 'calc(100% + 30px)' }}
          >
            {searchType === 'staff' && (<ul className='max-h-[500px]'>
              {searchResults.map((result, index) => (
                <li
                  key={result.id || index}
                  className={`flex flex-col p-3 rounded mb-2 hover:bg-blue-200/80 cursor-pointer ${result.id ? 'bg-blue-100 hover:bg-blue-200/80' : 'bg-violet-100 hover:bg-violet-200/80'}`}
                  onClick={() => viewClient(result)}
                >
                  <span className="font-bold text-lg">{result?.name ?? result[0].name}</span>
                  <div className="flex">
                    <span className="font-semibold mr-1">Date of Birth:</span>
                    {moment(result?.date_of_birth ?? result[0].date_of_birth).format('DD/MM/YYYY')}
                  </div>
                  <div className="flex">
                    <span className="font-semibold mr-1">Organisation:</span>
                    {result?.school_name ?? result[0].school_name}
                  </div>
                </li>
              ))}
            </ul>)}

            {searchType === 'organisation' && (<ul className='max-h-[500px]'>
              {searchResults.map((result, index) => (
                <li
                  key={result.id || index}
                  className={`flex flex-col p-3 bg-blue-100 rounded mb-2 hover:bg-blue-200/80 cursor-pointer ${result.id ? 'bg-blue-100 hover:bg-blue-200/80' : 'bg-violet-100 hover:bg-violet-200/80'}`}
                  onClick={() => viewClient(result)}
                >
                  <span className="font-bold text-lg">{result.name}</span>
                  <div className="flex">
                    <span className="font-semibold mr-1">Type:</span>
                    {result?.type === "1" ? 'Academy' : (result.type === "2" ? 'Trust' : 'Company')}
                  </div>
                  <div className="flex">
                    <span className="font-semibold mr-1">Reference:</span>
                    {result?.unique_reference}
                  </div>
                </li>
              ))}
            </ul>)}

            {searchType === 'referral' && (<ul className='max-h-[500px]'>
              {searchResults.map((result, index) => (
                <li
                  key={result.id || index}
                  className={`flex flex-col p-3 bg-blue-100 rounded mb-2 hover:bg-blue-200/80 cursor-pointer ${result.id ? 'bg-blue-100 hover:bg-blue-200/80' : 'bg-violet-100 hover:bg-violet-200/80'}`}
                  onClick={() => viewClient(result)}
                >
                  <span className="font-bold text-lg">{result.claim_reference}</span>
                  <div className="flex">
                    <span className="font-semibold mr-1">Service:</span>
                    {result?.service}
                  </div>
                  <div className="flex">
                    <span className="font-semibold mr-1">Status:</span>
                    {result?.status}
                  </div>
                </li>
              ))}
            </ul>)}

          </div>
        ) : searchQuery.length > 0 ? (
          <div
            className="absolute -left-4 bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px] z-10"
            style={{ top: 'calc(100% + 30px)' }}
          >
            <ul>
              <li className="flex flex-col p-3 bg-light-purple rounded mb-2 hover:bg-[#E5D5EF] cursor-pointer">
                <span className="font-bold text-lg">No results found</span>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </header>
  )
}

export default Header
