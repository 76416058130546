import { faBuilding, faChevronCircleRight, faEnvelope, faFolderPlus, faHouse, faLocationDot, faPhone, faSquarePhone, faVoicemail, faChevronCircleDown, faVideoCamera, faEllipsis, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import EditClient from './EditClientSlideOut'
import moment from 'moment'

function ClientTitle({ client, status = null, backButton = false, child = false, links = false, icon = false, toolTipData= null}) {
  const navigate = useNavigate()
  const [showMoreDetails, setShowMoreDetails] = useState(false)
  const [open, setOpen] = useState(false)

  const toggleMoreDetails = () => {
    setShowMoreDetails(!showMoreDetails);
  }

  const editClient = () => {
    setOpen(true)
  }

  const handleViewOrganisation = () => {
    navigate(`/organisations/${client.organisation?.id}`);
  }

  const getGenderFromPronouns = (pronouns) => {
    if (!pronouns) return 'Unknown';

    pronouns = pronouns.toLowerCase();
    if (pronouns.includes('her')) return 'Female';
    if (pronouns.includes('him')) return 'Male';
    if (pronouns.includes('they')) return 'Non-binary';
    return 'Unknown';
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  };

  const gender = getGenderFromPronouns(client.client_details?.pronouns);
  const age = calculateAge(client.client_details?.date_of_birth);

  return (
    <div className="pb-4 text-gray-700">
        <EditClient open={open} setOpen={setOpen} client={client} />

      <div className='bg-white rounded-lg p-4 flex justify-between text-gray-700'>
        <div className='w-2/3'>
          <div>
            <h2 className='font-semibold text-3xl'>
            {client.name} <span className='font-normal text-sm text-gray-500'>{client.client_details?.pronouns}</span>
            </h2>
            <div className='flex items-center space-x-4 text-sm text-gray-500'>
              <div>{moment(client.client_details?.date_of_birth).format('DD/MM/YYYY')} </div>
              <div>|</div>
              <div>{age} </div>
              <div>|</div>
              <div>{gender} </div>
            </div>
          </div>
          <div className='flex items-start justify-between text-sm mt-8'>
            <div className='space-y-2'> 
              <div> <FontAwesomeIcon icon={faPhone} className="text-sm" /> <span className='pl-2'>{client.client_details?.phone_number}</span></div>
              <div> <FontAwesomeIcon icon={faEnvelope} className="text-sm" /> <span className='pl-2'>{client.email}</span></div>
              <div className='text-[#71ADFF] cursor-pointer' onClick={toggleMoreDetails}> <FontAwesomeIcon icon={showMoreDetails ? faChevronCircleDown : faChevronCircleRight} className="text-sm" /> <span className='pl-2 hover:underline'>More Details</span></div>
                {showMoreDetails && (
                  <div className="mt-2 space-y-1">
                    <div> 
                      <FontAwesomeIcon icon={faHouse} className="text-sm" /> 
                      <span className='pl-2'>{client.client_details?.home_address}</span>
                    </div>
                    <div> 
                      <FontAwesomeIcon icon={faVoicemail} className="text-sm" /> 
                      <span className='pl-2'>{client.client_details?.preferred_contact_time}</span>
                    </div>
                    <div className='flex items-center space-x-2'>
                      <FontAwesomeIcon icon={faVideoCamera} className="text-sm" /> 
                      {client.contact_methods.length > 0 && (
                        <div className="flex space-x-2">
                          {client.contact_methods.map((method) => {
                            return <div key={method.id}>{method.title}</div>
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
            <div className='border-l h-auto mx-4'></div>
            <div className='space-y-2'> 
              <div> <FontAwesomeIcon icon={faBuilding} className="text-sm" /> <span className='pl-2'>{client.organisation?.name}</span></div>
              <div> <FontAwesomeIcon icon={faLocationDot} className="text-sm"/> <span className='pl-2'>{client.organisation?.unique_reference}</span></div>
              <div onClick={handleViewOrganisation} className='text-[#71ADFF] cursor-pointer'> <FontAwesomeIcon icon={faChevronCircleRight} className="text-sm" /> <span className='pl-2 hover:underline'>View {client.organisation?.type}</span></div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-between'>
          <div className='flex items-center justify-end'>
            {status && (
                <span className={`status ${status.replace(/\s+/g, '-').toLowerCase()}`}>
                  {status}
                </span>
            )}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2 hover:bg-gray-300 px-2 mx-auto">
                  <span className="font-semibold text-xl">
                    <FontAwesomeIcon icon={faEllipsis} className='text-nav-dark' />
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-2 py-1 space-y-2 text-sm">
                      <Menu.Item>
                        <div onClick={editClient} title="View Enquiry" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-[#F2EDF5] cursor-pointer">
                          <FontAwesomeIcon icon={faEdit} className='text-nav' /> <div className='pl-2'>Edit</div>
                        </div>
                      </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className='flex items-end justify-end text-xs space-x-2'>
            <div><button className='bg-teal-500 rounded-lg px-4 py-1.5 text-white font-medium'>Create Referral <FontAwesomeIcon icon={faFolderPlus} className="" /></button></div>
            <div><FontAwesomeIcon icon={faSquarePhone} className="text-3xl text-[#71ADFF]" /> </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientTitle
