import React, { useEffect, useState } from 'react';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useAuth from "../../../../hooks/useAuth";

const OHResultForm = ({setShowForm, claim, policy}) => {
    const axios = useAxiosPrivate();
    const { setShowLoader, createInfo, auth } = useAuth();
    const [result, setResult] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowLoader(true)

        const formData = new FormData()
        formData.append(
            'data',
            JSON.stringify({
                claimNumber: claim.reference,
                employer: e.target.employer.value,
                employerEmail: e.target.employerEmail.value,
                employeeName: e.target.employeeName.value,
                employeeDOB: e.target.employeeDOB.value,
                employeeRole: e.target.employeeRole.value,
                product: policy.product,
                department: policy.department,
                result: result
            })
        );

        await axios.post('claims/send-wbs-form/oh-result', formData).then(() => {
            createInfo('success', 'Form Sent')
            setShowForm(false);
            setShowLoader(false)
        }).error((response) => {
            createInfo('error', 'Failed to send Form')
            console.error(response)
            setShowLoader(false)
        })

        setShowLoader(false)
    }

    return (
        <section>
            <form onSubmit={handleSubmit}>
                <div className={"flex mb-2"}>
                    <div className={"flex flex-col w-1/2 pr-1"}>
                        <label className={"text-md"}>Employer/School Name</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="employer" required></input>
                    </div>

                    <div className={"flex flex-col w-1/2 pl-1"}>
                        <label className={"text-md"}>Employer/School Email</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="email" id="employerEmail" required></input>
                    </div>
                </div>

                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Prospective Employee Name</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="employeeName" required></input>
                </div>

                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Prospective Employee Date of Birth</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="date" id="employeeDOB" required></input>
                </div>

                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Role</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="employeeRole" required></input>
                </div>

                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Result</label>
                    <div className={"ml-2"}>
                        <div className={"flex mb-2"}>
                            <input value="a" type="radio" name="oh_result" className={"mr-2"} id="oh_result_a" onClick={() => {setResult('a')}} required></input>
                            <label>No barrier to employment in role disclosed</label>
                        </div>
                        <div className={"flex mb-2"}>
                            <input value="b" type="radio" name="oh_result" className={"mr-2"} id="oh_result_b" onClick={() => {setResult('b')}} required></input>
                            <label>One or more issues disclosed, resolved after discussions with our Nurse Team. No barrier to employment</label>
                        </div>
                        <div className={"flex"}>
                            <input value="c" type="radio" name="oh_result" className={"mr-2"} id="oh_result_c" onClick={() => {setResult('c')}} required></input>
                            <label>Issues disclosed, Occupational Health referral advised</label>
                        </div>
                    </div>
                </div>
                
                <button className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2 mt-2">Send OH Result Form</button>
            
            </form> 
        </section>
        
    )
}

export default OHResultForm