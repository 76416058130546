import { Routes, Route } from 'react-router-dom'

import Login from './pages/Login'
import TwoFa from './pages/TwoFactor'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'

import Missing from './pages/Missing'

// Admin pages
import AdminDashboard from './pages/Admin/Dashboard'
import Enquiry from './pages/Admin/Enquiry'
import Clients from './pages/Admin/Clients'
import Organisations from './pages/Admin/Organisations'
import Client from './pages/Admin/Clients/Client'
import Organisation from './pages/Admin/Organisations/Organisation'
import ClientHistory from './pages/Admin/Clients/ClientHistory'
import ClientEnquiries from './pages/Admin/Clients/ClientEnquiries'
import ClientDocuments from './pages/Admin/Clients/Documents'
import OrganisationEnquiries from './pages/Admin/Organisations/Enquiries'
import OrganisationStaff from './pages/Admin/Organisations/Staff'
import ClientNotes from './pages/Admin/Clients/ClientNotes'
import ClientComms from './pages/Admin/Clients/Comms'
import OrgainisationNotes from './pages/Admin/Organisations/Notes'
import OrganisationHistory from './pages/Admin/Organisations/History'
import Enquiries from './pages/Admin/Enquiries'
import EnquiryForm from './pages/EnquiryForm'
import ClaimCreate from './pages/Admin/Clients/ClaimCreate'
import ViewClaim from './pages/Admin/Clients/ViewClaim'
import AdminReports from './pages/Admin/Reports'
import AdminCalendar from './pages/Admin/Calendar'
import AdminAccounts from './pages/Admin/Accounts'
import AdminSettings from './pages/Admin/Settings'
import AdminPermissions from './pages/Admin/Settings/Permissions'
import AdminUsers from './pages/Admin/Settings/Users'
import AdminTools from './pages/Admin/Tools'
import AdminUser from './pages/Admin/User'
import AdminAccountSettings from './pages/Admin/AccountSettings'
import Tasks from './pages/Admin/Tasks'
import PPQReview from './pages/Admin/PPQReview'

import AdminNotifications from './pages/Admin/Tools/Notifications'

import Notifications from './pages/Notifications'

import Layout from './components/Layout'

import MainLayout from './layouts/MainLayout'
import AuthLayout from './layouts/AuthLayout'

import RequireAuth from './components/RequireAuth'
import PersistLogin from './components/PersistLogin'
import CheckLogin from './components/CheckLogin'

import useAuth from './hooks/useAuth'
import AvailabilityCalendar from './components/AvailabilityCalendar'
import ClientContacts from './pages/Admin/Clients/ClientContacts'

function App() {
  const { admin } = useAuth()

  return (
    <Routes>
      <Route element={<Layout />}>
        {/* Public Routes */}
        <Route path="/new-enquiry" element={<EnquiryForm />} />
       
        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route element={<MainLayout />}>
                <Route>
                  <Route path="/" element={<AdminDashboard />} />
                  <Route path="/calendar" element={<AdminCalendar />} />
                  <Route path="/accounts" element={<AdminAccounts />} />
                  
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/organisations" element={<Organisations />} />
                  <Route path="/organisations/:id" element={<Organisation />} />
                  <Route path="/organisations/:id/enquiries" element={<OrganisationEnquiries />} />
                  <Route path="/organisations/:id/staff" element={<OrganisationStaff />} />
                  <Route path="/organisations/:id/history" element={<OrganisationHistory />} />
                  <Route path="/organisations/:id/notes" element={<OrgainisationNotes />} />
                  <Route path="/clients/:id" element={<Client />} />
                  <Route path="/clients/:id/new-claim" element={<ClaimCreate />} />
                  <Route path="/clients/:id/referrals/:claim" element={<ViewClaim />} />
                  <Route path="/clients/:id/history" element={<ClientHistory />} />
                  <Route path="/clients/:id/comms" element={<ClientComms />} />
                  <Route path="/clients/:id/documents" element={<ClientDocuments />} />
                  <Route path="/clients/:id/enquiries" element={<ClientEnquiries />} />
                  <Route path="/clients/:id/notes" element={<ClientNotes />} />
                  <Route path="/clients/:id/contacts" element={<ClientContacts />} />

                  <Route path="/enquiries" element={<Enquiries />} />
                  <Route
                    path="/enquiries/:id"
                    element={<Enquiry />}
                  />

                  <Route path="/reports" element={<AdminReports />} />
                  <Route path="/settings" element={<AdminSettings />} />
                  <Route path="/tasks" element={<Tasks />} />
                  <Route path="/ppq-review/:id/:taskId" element={<PPQReview />} />

                  <Route
                    path="/settings/permissions"
                    element={<AdminPermissions />}
                  />
                  <Route path="/settings/users" element={<AdminUsers />} />
                  <Route path="/users/:id" element={<AdminUser />} />
                  <Route
                    path="/users/:id/availability"
                    element={<AvailabilityCalendar />}
                  />
                  <Route
                    path="/users/:id/account-settings"
                    element={<AdminAccountSettings />}
                  />
                  <Route path="/tools" element={<AdminTools />} />
                  <Route
                    path="/tools/notifications"
                    element={<AdminNotifications />}
                  />
                </Route>
              <Route path="/notifications" element={<Notifications />} />
            </Route>
            
            <Route path="*" element={<Missing />} />
          </Route>

          <Route element={<CheckLogin />}>
            <Route element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="2fa" element={<TwoFa />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>

          {/* Catch All */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
